// /src/components/common/Report.js
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PatientContext from "../../../context/PatientContext";
import Cookies from "js-cookie";
import AICVDPatientReport from "../../Reports/AICVDReport";
import PrediabetesPatientReport from "../../Reports/PrediabetesReportPage";
import LiverReport from "../../Reports/LiverReport";
import OuterFrame from "../OuterFrame";
import "./index.css";
import { ColorRing } from "react-loader-spinner";

const Report = (props) => {
  const navigate = useNavigate();
  const [patientReport, setPatientReport] = useState("");
  const [current_app, setApp] = useState(props.app);
  const { setContextPatientReport } = useContext(PatientContext);
  useEffect(() => {
    const fetchData = async () => {
      const url = props.REPORT_URL;
      const jwt_token = Cookies.get("jwt_token");
      let myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${jwt_token}`);


      const options = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(props.formDetails),
        redirect: "follow",
      };

      try {
        const response = await fetch(url, options);
        const responseObj = await response.json();
        if (response.ok) {
          setPatientReport(responseObj);
          setContextPatientReport(responseObj);
        } else {
          alert("Failed to fetch the data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    setApp(props.app);
  }, [props.REPORT_URL, props.app, props.formDetails, setContextPatientReport]);

  const handleGeneratePdf = () => {
    // Logic to generate PDF from the report
    navigate(`/${current_app}/pdfreport`); // Navigate to PDF report page
  };


  return (
    <OuterFrame
      appName={
        props.app && props.app === "prediabetes"
          ? "PREDIABETES AI SCORE"
          : props.app === "aicvd"
          ? "AICVD SCORE"
          : props.app === "Liver"
          ? "AILF SCORE"
          : null
      }
      hightlight="3"
      color={props.color}
      secondaryColor={props.secondaryColor}
      surface_color={props.surface_color}
      surface_highlight_color={props.surface_highlight_color}
      surface_hover_color={props.surface_hover_color}
      button_hover_color={props.button_hover_color}
      component={
        <div className="container-div">
          {patientReport !== "" ? (
            <div className="report-form-page-conatiner">
              {props.app === "aicvd" ? (
                <AICVDPatientReport data={patientReport} />
              ) : props.app === "prediabetes" ? (
                <PrediabetesPatientReport data={patientReport} />
              ) : (
                <LiverReport data={patientReport} />
              )}

              <div className="submit-button-container">
                <button
                  type="submit"
                  className="submit-button"
                  onClick={handleGeneratePdf}
                >
                  View PDF
                </button>
              </div>
            </div>
          ) : (
            <div className="app-loading-container">
              <div className="login-loader-container">
                <ColorRing
                  height="80"
                  width="80"
                  ariaLabel="bars-loading"
                  visible={true}
                  colors={[
                    "#a09be4",
                    "#a09be4",
                    "#a09be4",
                    "#a09be4",
                    "#a09be4",
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default Report;
