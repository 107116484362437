import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import OuterFrame from "../../common/OuterFrame";
import { Oval } from "react-loader-spinner";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faRuler,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faIdBadge,
  faBirthdayCake,
  faVenusMars,
  faWeight,
  faBalanceScale,
  faDna,
  faHeartPulse,
  faHeartbeat,
  faTint,
  faVials,
  faMicroscope,
  faFlask,
  faVial,
} from "@fortawesome/free-solid-svg-icons";
import SidebarOverlay from "../../Sidebar";
import PopupContainer from "../../PopupContainer";
import ConcentForm from "../../common/PaitentConcent";

const DetailsPage = (props) => {
  const navigate = useNavigate();
  const [loaderstatus, setLoaderStatus] = useState(true); // Start with true
  const [isDataReady, setIsDataReady] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});

  const [selectedOptions, setSelectedOptions] = useState({});
  const [subOptionValues, setSubOptionValues] = useState({});
  const [formData, setFormData] = useState({});
  const [warningMessage, setWarningMessage] = useState("");
  const [activeId, setActiveId] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [isChecked1, setIsChecked1] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [diabetesCondition, setDiabetesCondition] = useState("");

  const isEmptyValue = (value) => {
    return value === undefined || value === null || value === "";
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    patientInfo.Patient_Details?.forEach((field) => {
      if (field.required === "yes" && isEmptyValue(formData[field.key])) {
        errors[field.key] = "This field is required";
        isValid = false;
      }
    });

    patientInfo.Attributes?.[0]["Physical Attributes"]?.forEach((field) => {
      if (field.required === "yes" && isEmptyValue(formData[field.key])) {
        errors[field.key] = "This field is required";
        isValid = false;
      }
    });

    patientInfo.Attributes?.[1]["Medical History"]?.forEach((field) => {
      if (field.required === "yes" && isEmptyValue(formData[field.key])) {
        errors[field.key] = "This field is required";
        isValid = false;
      }
    });

    patientInfo.Attributes?.[3]["Lab History"]?.forEach((field) => {
      if (field.required === "yes" && isEmptyValue(formData[field.key])) {
        errors[field.key] = "This field is required";
        isValid = false;
      }
    });

    setValidationErrors(errors);
    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setWarningMessage("");
    if (!validateForm()) {
      setWarningMessage("Please fill in all required fields");
      return;
    } else {
      props.form_details && props.form_details(formData);
      navigate(`/liver/report`);
    }
  };

  const toggleSidebar = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  const handleOptionChange = (key, value) => {
    setSelectedOptions((prev) => ({ ...prev, [key]: value }));
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubOptionChange = (parentKey, subKey, value, type) => {
    setSubOptionValues((prev) => ({
      ...prev,
      [parentKey]: { ...prev[parentKey], [subKey]: value },
    }));
    setFormData((prev) => ({
      ...prev,
      [subKey]: value,
    }));

    if (parentKey === "diabetes" && type === "number") {
      updateDiabetesYesValue(parentKey, value);
    }
  };

  const findFieldByKey = (key) => {
    // Search in Patient Details
    const patientField = patientInfo.Patient_Details?.find(
      (f) => f.key === key
    );
    if (patientField) return patientField;

    // Search in Physical Attributes
    const physicalField = patientInfo.Attributes?.[0][
      "Physical Attributes"
    ]?.find((f) => f.key === key);
    if (physicalField) return physicalField;

    // Search in Medical History
    const medicalField = patientInfo.Attributes?.[1]["Medical History"]?.find(
      (f) => f.key === key
    );
    if (medicalField) return medicalField;

    // Search in Lab History
    const labField = patientInfo.Attributes?.[3]["Lab History"]?.find(
      (f) => f.key === key
    );
    if (labField) return labField;

    // If no field is found
    return null;
  };

  const handleInputChange = (key, value) => {
    const field = findFieldByKey(key);

    if (field?.ranges && value !== "") {
      const numValue = Number(value);
      if (numValue < field.ranges[0] || numValue > field.ranges[1]) {
        setValidationErrors((prev) => ({
          ...prev,
          [key]: `Value must be between ${field.ranges[0]} and ${field.ranges[1]}`,
        }));
      } else {
        setValidationErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[key];
          return newErrors;
        });
      }
    }

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleMainOptionChange = (key, value) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = {
        ...prevSelectedOptions,
        [key]: value,
      };
      return updatedOptions;
    });

    setFormData((prevAttributeValues) => {
      const updatedValues = {
        ...prevAttributeValues,
        [key]: value,
      };
      return updatedValues;
    });
  };

  const handleCheckboxChange = (key, value) => {
    setFormData((prevAttributeValues) => {
      const currentValues = prevAttributeValues[key] || [];
      let newValues;

      if (value === "None") {
        newValues = ["None"];
      } else {
        const filteredValues = currentValues.filter((v) => v !== "None");

        newValues = filteredValues.includes(value)
          ? filteredValues.filter((v) => v !== value)
          : [...filteredValues, value];
      }

      const updatedValues = {
        ...prevAttributeValues,
        [key]: newValues,
      };
      return updatedValues;
    });
  };

  useEffect(() => {
    const initializeData = async () => {
      setLoaderStatus(true);
      try {
        const savedContent = JSON.parse(localStorage.getItem("fields"));

        if (savedContent) {
          setPatientInfo(savedContent);
          setIsDataReady(true);
        }
      } catch (error) {
      } finally {
        // Give a slight delay
        // Give a slight delay to ensure state updates are processed
        setTimeout(() => {
          setLoaderStatus(false);
        }, 500);
      }
    };

    initializeData();
  }, [props.app]);

  const calculateBMI = useCallback((height, weight) => {
    if (height && weight) {
      let bmi = weight / Math.pow(height * 0.01, 2);
      let decimal = bmi - Math.floor(bmi);
      bmi = Math.floor(bmi) + Math.floor(decimal * 100) / 100;
      return bmi;
    }
    return null;
  }, []);

  useEffect(() => {
    const height = formData.height;
    const weight = formData.weight;
    if (height && weight) {
      const bmi = calculateBMI(height, weight);
      setFormData((prevValues) => {
        if (prevValues.BMI !== bmi) {
          return {
            ...prevValues,
            BMI: bmi,
          };
        }
        return prevValues;
      });
    }
  }, [formData.height, formData.weight, calculateBMI]);

  const renderAttributes = (attributes, section) => {
    return attributes?.map((attribute, index) => (
      <div className="form-field-container">
        <div key={index} className="form-field">
          <FontAwesomeIcon
            icon={getIconForField(attribute.name)}
            className="field-icon"
          />
          <>
            <input
              type="text"
              placeholder=""
              value={formData[attribute.key] || ""}
              onFocus={(e) => {
                handleFieldFocus(attribute.key);
                e.target.placeholder = getPlaceholderText(attribute);
              }}
              onBlur={(e) => {
                e.target.placeholder = "";
              }}
              onChange={(e) => handleInputChange(attribute.key, e.target.value)}
              readOnly={attribute.key === "bmi" || attribute.key === "BMI"} // Make BMI field readonly
              className={`${validationErrors[attribute.key] ? "error" : ""} 
                       ${
                         attribute.key === "bmi" || attribute.key === "BMI"
                           ? "readonly"
                           : ""
                       }`}
            />
            <label>
              {attribute.name}
              {attribute.required === "yes" && (
                <span className="required">*</span>
              )}
            </label>
            {attribute.description && attribute.description !== "" && (
              <span>
                <button
                  type="button"
                  onClick={() => toggleSidebar(attribute.name)}
                  className="description-bar"
                >
                  i
                </button>
                {activeId === attribute.name && (
                  <SidebarOverlay
                    id={attribute.name}
                    description={attribute.description}
                    activeId={activeId}
                    name={attribute.name}
                    setActiveId={setActiveId}
                    toggleSidebar={toggleSidebar}
                  />
                )}
              </span>
            )}
          </>
        </div>
        {validationErrors[attribute.key] && (
          <span className="error-message">
            {validationErrors[attribute.key]}
          </span>
        )}
      </div>
    ));
  };

  const renderLifeStyle = (lifestyle) => {
    return (
      <div className="lifestyle-form-grid">
        {lifestyle?.map((item, index) => (
          <div key={index} className="lifestyle-card">
            <p>
              {item.name}
              {item.required === "yes" && <span className="required">*</span>}
              {item.description && item.description !== "" && (
                <span>
                  <button
                    type="button"
                    onClick={() => toggleSidebar(item.name)}
                    className="description-bar"
                  >
                    i
                  </button>
                  {activeId === item.name && (
                    <SidebarOverlay
                      id={item.name}
                      description={item.description}
                      activeId={activeId}
                      name={item.name}
                      setActiveId={setActiveId}
                      toggleSidebar={toggleSidebar}
                    />
                  )}
                </span>
              )}
            </p>

            {item.type === "radio" && (
              <div className="radio-group">
                <select
                  name={item.key}
                  value={selectedOptions[item.key] || ""}
                  onChange={(e) => handleOptionChange(item.key, e.target.value)}
                >
                  <option value="" defaultValue>
                    Select
                  </option>
                  {item.options.map((option, optIndex) => (
                    <option key={optIndex} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>

                {item.options.map(
                  (option, optIndex) =>
                    formData[item.key] === option.name &&
                    option.alert && (
                      <div
                        className="popup-main-container"
                        id={`${item.key}_${option.key}`}
                        key={`popup_${optIndex}`}
                      >
                        <div className="warring-popup-container">
                          <h1 className="warring-popup-content-heading">
                            Warning..!
                          </h1>
                          <p className="popup-text-highlight-text">
                            {option.alert.msg}
                          </p>
                          <button
                            className="button warring-button"
                            type="button"
                            onClick={() =>
                              toggleWarning(`${item.key}_${option.key}`)
                            }
                          >
                            CONTINUE
                          </button>
                        </div>
                      </div>
                    )
                )}

                {item.options
                  .find((option) => option.name === selectedOptions[item.key])
                  ?.sub_options?.map((subOption, subIndex) => (
                    <div key={subIndex} className="sub-option">
                      {subOption.type === "number" ? (
                        <div className="fatty-liver-section">
                          <label>
                            {subOption.name === "No of years"
                              ? "Liver Disease Since"
                              : subOption.name}
                          </label>
                          <input
                            type="number"
                            placeholder={subOption.name}
                            value={
                              subOptionValues[item.key]?.[subOption.key] || ""
                            }
                            onChange={(e) =>
                              handleSubOptionChange(
                                item.key,
                                subOption.key,
                                e.target.value,
                                "1st function"
                              )
                            }
                          />
                        </div>
                      ) : (
                        <div className="sub-option-radio">
                          <input
                            type="radio"
                            className="radio-button"
                            id={`${item.key}-sub-${subOption.value}`}
                            name={`${item.key}-sub`}
                            value={subOption.value}
                            checked={
                              subOptionValues[item.key]?.[`${item.key}-sub`] ===
                              subOption.value
                            }
                            onChange={(e) =>
                              handleSubOptionChange(
                                item.key,
                                `${item.key}-sub`,
                                subOption.value,
                                "second function"
                              )
                            }
                          />
                          <span className="c-radio"></span>
                          <label
                            htmlFor={`${item.key}-sub-${subOption.value}`}
                            className="sub-option-radio-label"
                          >
                            {subOption.name}
                          </label>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const toggleWarning = (id) => {
    const WarningBoxEl = document.getElementById(id);
    WarningBoxEl.style.display = "none";
  };

  const renderMedicalHistory = (medicalHistory) => {
    return (
      <div className="medical-history-form-grid">
        {medicalHistory?.map((item, index) => (
          <div key={index} className="card">
            <p>
              {item.name}{" "}
              {item.description && (
                <span>
                  <button
                    type="button"
                    onClick={() => toggleSidebar(item.name)}
                    className="description-bar"
                  >
                    i
                  </button>
                  {activeId === item.name && (
                    <SidebarOverlay
                      id={item.name}
                      description={item.description}
                      activeId={activeId}
                      name={item.name}
                      setActiveId={setActiveId}
                      toggleSidebar={toggleSidebar}
                    />
                  )}
                </span>
              )}
            </p>

            {item.type === "select" && (
              <div className="select-group">
                <select
                  name={item.key}
                  value={selectedOptions[item.key] || ""}
                  onChange={(e) =>
                    handleMainOptionChange(item.key, e.target.value)
                  }
                >
                  <option value="">Select</option>
                  {item.options.map((option, optIndex) => (
                    <option key={optIndex} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>

                <div
                  className={`${
                    item.name === "Diabetes Mellitus"
                      ? "input-date-container"
                      : null
                  }`}
                >
                  {item.options.map(
                    (option, optIndex) =>
                      formData[item.key] === option.name &&
                      option.sub_options?.map((subOption, subIndex) => (
                        <div key={subOption.value} className="">
                          <div key={subIndex} className="sub-option">
                            {subOption.type === "checkbox" ? (
                              <div className="checkbox-input-box checkbox_input_box_in_input_component">
                                <div className="checkbox-container">
                                  <input
                                    name={subOption.key}
                                    id={`${subOption.key}_${subOption.name}`}
                                    type={subOption.type}
                                    value={subOption.value}
                                    onChange={(event) =>
                                      handleCheckboxChange(
                                        subOption.key,
                                        event.target.value
                                      )
                                    }
                                    className="checkbox-input"
                                    ischeckbox={
                                      formData[subOption.key] &&
                                      formData[subOption.key].includes(
                                        subOption.value
                                      )
                                    }
                                  />

                                  <span className="custom-checkbox">
                                    <img
                                      src="checkmark-outline-white.svg"
                                      alt="checkmark-outline-white"
                                    />
                                  </span>
                                </div>

                                <div className="inputbox checkbox_lable_box_input_in_input_component">
                                  <label
                                    className="lable_in_input_component checkbox_lable_in_input_component"
                                    htmlFor={`${subOption.key}_${subOption.name}`}
                                  >
                                    {subOption.value}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <>{renderSubOption(subOption, item.key)}</>
                            )}
                          </div>
                          {subOption.type === "checkbox" &&
                            formData[subOption.key]?.includes(
                              subOption.value
                            ) &&
                            subOption.sub_options && (
                              <div className="sub-option-container">
                                {subOption.sub_options.map((subSubOption) => (
                                  <div
                                    key={subSubOption.value}
                                    className="sub-option-container"
                                  >
                                    <div className="checkbox-input-box checkbox_input_box_in_input_component">
                                      <div className="checkbox-container">
                                        <input
                                          name={subSubOption.key}
                                          id={`${subOption.key}_${subSubOption.name}`}
                                          type={subSubOption.type}
                                          value={subSubOption.value}
                                          onChange={(event) =>
                                            handleCheckboxChange(
                                              subSubOption.key,
                                              event.target.value
                                            )
                                          }
                                          className="checkbox-input"
                                          ischeckbox={
                                            formData[subSubOption.key] &&
                                            formData[subSubOption.key].includes(
                                              subSubOption.value
                                            )
                                          }
                                        />

                                        <span className="custom-checkbox">
                                          <img
                                            src="checkmark-outline-white.svg"
                                            alt="checkmark-outline-white"
                                          />
                                        </span>
                                      </div>

                                      <div className="inputbox checkbox_lable_box_input_in_input_component">
                                        <label
                                          className="lable_in_input_component checkbox_lable_in_input_component"
                                          htmlFor={`${subOption.key}_${subSubOption.name}`}
                                        >
                                          {subSubOption.value}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      ))
                  )}
                </div>
              </div>
            )}

            {item.type === "checkbox" && (
              <>
                {item.options.map((option, optIndex) => (
                  <>
                    <div className="checkbox-input-box checkbox_input_box_in_input_component">
                      {formData[item.key]?.includes(option.value) &&
                        option.alert && (
                          <div
                            className="popup-main-container"
                            id={`${item.key}_${option.key}`}
                          >
                            <div className="warring-popup-container">
                              <h1 className="warring-popup-content-heading">
                                Warning..!
                              </h1>
                              <p className="popup-text-highlight-text">
                                {option.alert.msg}
                              </p>
                              <button
                                className="button warring-button"
                                type="button"
                                onClick={() =>
                                  toggleWarning(`${item.key}_${option.key}`)
                                }
                              >
                                CONTINUE
                              </button>
                            </div>
                          </div>
                        )}
                      <div className="checkbox-container">
                        <input
                          name={item.key}
                          id={`${item.key}_${option.value}`}
                          type={item.type}
                          value={option.value}
                          onChange={(event) =>
                            handleCheckboxChange(item.key, event.target.value)
                          }
                          className="checkbox-input"
                          checked={
                            formData[item.key] &&
                            formData[item.key]?.includes(option.value)
                          }
                        />

                        <span className="custom-checkbox">
                          <img
                            src="checkmark-outline-white.svg"
                            alt="checkmark-outline-white"
                          />
                        </span>
                      </div>
                      <div className="inputbox checkbox_lable_box_input_in_input_component">
                        <label
                          className="lable_in_input_component checkbox_lable_in_input_component"
                          htmlFor={`${item.key}_${option.value}`}
                        >
                          {option.value}
                        </label>
                      </div>
                    </div>
                    {formData[item.key]?.includes(option.value) &&
                      !formData[item.key]?.includes("None") &&
                      option.sub_options &&
                      option.sub_options.map((subOption, subIndex) => (
                        <div key={subIndex}>
                          <div className="fatty-liver-section">
                            <label className="sub-option-label">
                              {subOption.name}
                            </label>
                            <input
                              type="number"
                              placeholder={subOption.name}
                              value={
                                subOptionValues[item.key]?.[subOption.key] || ""
                              }
                              className="sub-option-input"
                              onChange={(e) =>
                                handleSubOptionChange(
                                  item.key,
                                  subOption.key,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </div>
                      ))}
                  </>
                ))}
              </>
            )}

            {item.name === "Diabetes Mellitus" && diabetesCondition !== "" && (
              <p className="diabetes-highlighted-text">
                Your Diabetes is
                <span
                  className={`${
                    diabetesCondition === "Controlled Blood Sugar" ||
                    diabetesCondition === "Normal"
                      ? "green-text"
                      : "red-text"
                  }`}
                >
                  {diabetesCondition}
                </span>
              </p>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderSubOption = (subOption, parentKey) => {
    return (
      <div className="sub-option-wrapper">
        {subOption.type === "number" && (
          <div className="number-input-container">
            <label>{subOption.name}</label>
            <input
              type="number"
              value={subOptionValues[parentKey]?.[subOption.key] || ""}
              onChange={(e) =>
                handleSubOptionChange(
                  parentKey,
                  subOption.key,
                  e.target.value,
                  subOption.type
                )
              }
            />
          </div>
        )}

        {subOption.type === "date" && (
          <div className="date-picker-container">
            <label>{subOption.name}</label>
            <input
              type="date"
              value={subOptionValues[parentKey]?.[subOption.key] || ""}
              onChange={(e) =>
                handleSubOptionChange(parentKey, subOption.key, e.target.value)
              }
            />
          </div>
        )}
      </div>
    );
  };

  const updateDiabetesYesValue = (parentKey, value) => {
    let condition = "";

    // Convert to number for comparison
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) {
      if (formData[parentKey] === "Fasting Blood Sugar") {
        if (numericValue >= 126 && numericValue <= 170) {
          condition = "Controlled Blood Sugar";
        } else if (numericValue > 170) {
          condition = "Uncontrolled Blood Sugar";
        }
      } else if (formData[parentKey] === "HBA1c") {
        if (numericValue < 7.5) {
          condition = "Controlled Blood Sugar";
        } else {
          condition = "Uncontrolled Blood Sugar";
        }
      } else {
        if (numericValue >= 1.7 && numericValue <= 2.0) {
          condition = "Normal";
        } else {
          condition = "Abnormal";
        }
      }
    }

    setDiabetesCondition(condition);
  };

  const getIconForField = (fieldName) => {
    switch (fieldName.toLowerCase()) {
      case "email":
        return faEnvelope;
      case "location":
        return faMapMarkerAlt;
      case "phone":
        return faPhone;
      case "uhid":
        return faIdBadge;
      case "name":
        return faUser;
      case "age":
        return faBirthdayCake;
      case "gender":
        return faVenusMars;
      case "height":
        return faRuler;
      case "weight":
        return faWeight;
      case "bmi":
        return faBalanceScale;
      case "total bilirubin":
        return faVial;
      case "alkaline phosphatase":
        return faFlask;
      case "sgpt":
        return faMicroscope;
      case "sgot":
        return faMicroscope;
      case "total protiens":
        return faVials;
      case "albumin":
        return faTint;
      case "cholesterol":
        return faHeartbeat;
      case "ldl cholesterol":
        return faHeartPulse;
      case "platelet count":
        return faDna;
      default:
        return faUser;
    }
  };

  const handleFieldFocus = (key) => {
    if (validationErrors[key]) {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[key];
        return newErrors;
      });
    }
  };

  const getPlaceholderText = (field) => {
    if (field.ranges) {
      return `${field.ranges[0]} - ${field.ranges[1]}`;
    }
    return "";
  };

  const openPopup = () => setIsPopupOpen(true);

  const closePopup = () => setIsPopupOpen(false);

  const handleConsentCheckboxChange = (checkboxId) => {
    if (checkboxId === 1) {
      setIsChecked1((prev) => !prev);
    }
  };

  return (
    <OuterFrame
      hightlight="2"
      appName={"AILF SCORE"}
      color={props.color}
      secondaryColor={props.secondaryColor}
      surface_color={props.surface_color}
      surface_highlight_color={props.surface_highlight_color}
      surface_hover_color={props.surface_hover_color}
      button_hover_color={props.button_hover_color}
      component={
        loaderstatus || !isDataReady ? (
          <div className="loader-container">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="medical-form">
            <div className="form-sections">
              <section className="basic-info">
                <h2 className="patient-medical-info-heading">
                  Basic Information
                </h2>
                <div className="form-grid">
                  {patientInfo.Patient_Details.map((patient, index) => (
                    <div key={index} className="form-field-container">
                      <div className="form-field">
                        <FontAwesomeIcon
                          icon={getIconForField(patient.name)}
                          className="field-icon"
                        />
                        {patient.key === "gender" ? (
                          <select
                            value={formData.gender || ""}
                            onChange={(e) =>
                              handleInputChange("gender", e.target.value)
                            }
                            onFocus={() => handleFieldFocus(patient.key)}
                            className="gender-select"
                          >
                            <option value="" disabled>
                              Select Gender
                            </option>
                            {patient.options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <>
                            <input
                              type="text"
                              placeholder=""
                              value={formData[patient.key] || ""}
                              onFocus={(e) => {
                                handleFieldFocus(patient.key);
                                e.target.placeholder =
                                  getPlaceholderText(patient);
                              }}
                              onBlur={(e) => {
                                e.target.placeholder = "";
                              }}
                              onChange={(e) =>
                                handleInputChange(patient.key, e.target.value)
                              }
                            />
                            <label>
                              {patient.name}{" "}
                              {patient.required === "yes" && (
                                <span className="required">*</span>
                              )}
                            </label>
                          </>
                        )}
                      </div>
                      {validationErrors[patient.key] && (
                        <span className="error-message">
                          {validationErrors[patient.key]}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </section>
              <section className="physical-attributes">
                <h2 className="patient-medical-info-heading">
                  Physical Attributes
                </h2>
                <div className="form-grid">
                  {renderAttributes(
                    patientInfo.Attributes[0]["Physical Attributes"]
                  )}
                </div>
              </section>

              <section className="medical-history">
                <h2 className="patient-medical-info-heading">
                  Medical History
                </h2>
                {renderMedicalHistory(
                  patientInfo.Attributes[1]["Medical History"]
                )}
              </section>

              <section className="lifestyle">
                <h2 className="patient-medical-info-heading">Lifestyle</h2>
                {renderLifeStyle(patientInfo.Attributes[2].Lifestyle)}
              </section>
              <section className="lab-history">
                <h2 className="patient-medical-info-heading">Lab History</h2>
                <div className="form-grid">
                  {renderAttributes(patientInfo.Attributes[3]["Lab History"])}
                </div>
              </section>
              <div className="showConcentDiv">
                <span className="DocLogoForConcentButton" onClick={openPopup}>
                  <img
                    src="doc-icon.svg"
                    className="consent-icon"
                    alt="consent-icon"
                  />
                </span>
                <button
                  className="showConcentButton"
                  onClick={openPopup}
                  type="button"
                >
                  PATIENT INFORMATION SHEET AND INFORMED CONSENT
                </button>
              </div>
              <div className="checkBoxForTC">
                <div className="checkbox-for-patient-consent">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      id="loginCheckBox1"
                      checked={isChecked1}
                      onChange={() => handleConsentCheckboxChange(1)}
                    />
                    <span className="custom-checkbox">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                  </div>
                  <label
                    htmlFor="loginCheckBox1"
                    className="patient-consent-label-content"
                  >
                    Patient has read and understood the information sheet, had
                    the opportunity to ask questions, and agreed to participate
                    in the NCD Risk Scores at Apollo Hospitals.
                  </label>
                </div>
              </div>
            </div>
            {warningMessage !== "" ? (
              <p className="all-fields-warning-msg">{warningMessage}</p>
            ) : null}
            <div className="submit-button-container">
              <button type="submit" className="submit-button">
                Submit
              </button>
            </div>
            <PopupContainer
              isOpen={isPopupOpen}
              onClose={closePopup}
              title="STUDY PARTICIPANT INFORMATION SHEET"
            >
              <ConcentForm data={patientInfo.patient_consent} />
            </PopupContainer>
          </form>
        )
      }
    />
  );
};

export default DetailsPage;
