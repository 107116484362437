import React, { useEffect, useContext, useState, useRef, useMemo } from "react";
import Cookies from "js-cookie";
import FieldsContext from "../../../context/FieldsContext";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import "./index.css";

const AppLandingPage = (props) => {
  const { setFields } = useContext(FieldsContext);
  const [current_app] = useState(props.app);
  const [landingPage, setLandingPage] = useState(null);
  const navigate = useNavigate();
  const aboutRef = useRef();
  const validRef = useRef();
  const researchRef = useRef();
  const TakeMetoAppRef = useRef();
  const [termsofuse, setTermsOfUse] = useState(false);
  const [previousIconId, setPreviousIconId] = useState(null);
  const [clickTermsOfUse, setClickTermsOfUse] = useState(false);
  const [hover, setHover] = useState(false);
  const [takeHover, setTakeMeHover] = useState(false);
  const [takeMeButton, setTakeMeButton] = useState(false);

  const [tokenExpiry, setExpiry] = useState(false);
  const token = Cookies.get("sas_token");

  useEffect(() => {
    const fetchData = async () => {
      const url = props.LANDING_URL;
      const jwt_token = Cookies.get("jwt_token");
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${jwt_token}`);

      const options = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(url, options);
        const responseObj = await response.json();
        if (responseObj.status === "success") {
          setFields(responseObj.data);
          setLandingPage(responseObj.data.landing_page);
          localStorage.setItem("fields", JSON.stringify(responseObj.data));
        } else {
          alert("Failed to fetch the data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [setFields, navigate, props.LANDING_URL, props.app, current_app]);

  const logOut = () => {
    Cookies.remove("jwt_token");
    navigate("/login");
  };

  const triggerTermsBox = () => {
    setTermsOfUse(!termsofuse);
  };

  const OnChangeTermsOfUse = () => {
    setClickTermsOfUse(!clickTermsOfUse);
    setTakeMeButton(false);
  };

  const returnToHome = () => {
    navigate("/");
  };

  const handleClickScrollTo = (ref, id) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
    if (previousIconId && previousIconId !== id) {
      const previousIcon = document.getElementById(previousIconId);
      if (previousIcon) {
        previousIcon.style.backgroundColor = ""; // Reset to default
      }
    }
    const iconElement = document.getElementById(id);
    if (iconElement) {
      iconElement.style.backgroundColor = props.surface_highlight_color;
    }
    setPreviousIconId(id);
  };

  const takeMeToAction = () => {
    clickTermsOfUse && navigate(`/${current_app}/patient-details`);
    setTakeMeButton(true);
  };

  const getImageBlobUrl = useMemo(() => {
    const imageMap = new Map(props.images.map((img) => [img.id, img.blobUrl]));
    return (id) => imageMap.get(id) || "";
  }, [props.images]);

  function isTokenExpired(url) {
    try {
      const urlParams = new URL(url).searchParams;
      const expiryTime = urlParams.get("se");

      if (expiryTime) {
        const expiryDate = new Date(expiryTime);
        const now = new Date(); // Current time

        return now > expiryDate; // Return true if current time is past expiry
      }

      console.error("The 'se' parameter is missing in the URL.");
      setExpiry(true);
      return true; // Assume expired if 'se' is missing
    } catch (error) {
      console.error("Error parsing URL:", error);
      setExpiry(true);
      return true; // Assume expired in case of errors
    }
  }

  const isMobile = window.innerWidth <= 768;

  return landingPage ? (
    <div className="app-info-landing-page-container">
      <nav
        className="heading-bar-container"
        style={{ backgroundColor: props.surface_color }}
      >
        <div className="apollo-image-logo-container">
          <img
            src={
              isTokenExpired(
                `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo-logo.png?${token}`
              ) || tokenExpiry
                ? getImageBlobUrl(`${props.app}-image-1`)
                : `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo-logo.png?${token}`
            }
            alt="apollo-logo"
            className="apollo-image"
          />
        </div>
        <ul className="list-of-redirect-elements">
          <div className="landingpage-action-icons">
            <div
              id="about-icon"
              className="app-landing-page-icon-container"
              onClick={() => handleClickScrollTo(aboutRef, "about-icon")}
            >
              <img src="group.png" alt="about-icon" className="icon-landing" />
            </div>
            <li
              className="nav-link-buttons"
              onClick={() => handleClickScrollTo(aboutRef, "about-icon")}
            >
              About
            </li>
          </div>

          <div className="landingpage-action-icons">
            <div
              id="validation-icon"
              className="app-landing-page-icon-container"
              onClick={() => handleClickScrollTo(validRef, "validation-icon")}
            >
              <img
                src="validation.png"
                alt="validation-icon"
                className="icon-landing"
              />
            </div>

            <li
              className="nav-link-buttons"
              onClick={() => handleClickScrollTo(validRef, "validation-icon")}
            >
              Validation
            </li>
          </div>
          <div className="landingpage-action-icons">
            <div
              id="research-icon"
              className="app-landing-page-icon-container"
              onClick={() => handleClickScrollTo(researchRef, "research-icon")}
            >
              <img
                src="research-svgrepo-com.svg"
                alt="research-icon"
                className="icon-landing"
              />
            </div>

            <li
              className="nav-link-buttons"
              onClick={() => handleClickScrollTo(researchRef, "research-icon")}
            >
              The Research
            </li>
          </div>

          <div className="landingpage-action-icons">
            <div
              id="app-icon"
              className="app-landing-page-icon-container"
              onClick={() => handleClickScrollTo(TakeMetoAppRef, "app-icon")}
            >
              <img
                src="icons8-app.svg"
                alt="app-icon"
                className="icon-landing"
              />
            </div>

            <li
              className="nav-link-buttons"
              onClick={() => handleClickScrollTo(TakeMetoAppRef, "app-icon")}
            >
              App
            </li>
          </div>

          <div className="landingpage-action-icons">
            <div id="app-icon" className="app-landing-page-icon-container">
              {props.app === "aicvd" && (
                <a
                  className="app-landing-page-icon-container"
                  href="001-IFU - FAQ AICVD V1.1.pdf"
                  target="_blank"
                >
                  <img src="faq.png" alt="app-icon" className="icon-landing" />
                </a>
              )}
              {props.app === "liver" && (
                <a
                  className="app-landing-page-icon-container"
                  href="001-IFU - FAQ AILF 1.1.pdf"
                  target="_blank"
                >
                  <img src="faq.png" alt="app-icon" className="icon-landing" />
                </a>
              )}
              {props.app === "prediabetes" && (
                <a
                  className="app-landing-page-icon-container"
                  href="001-IFU - FAQ Prediabetes v1.1.pdf"
                  target="_blank"
                >
                  <img src="faq.png" alt="app-icon" className="icon-landing" />
                </a>
              )}
            </div>
            <li className="nav-link-buttons">FAQ</li>
          </div>
        </ul>
        <div className="logout-button-container-block">
          <div className="landingpage-action-icons">
            <div
              className="app-landing-page-icon-container"
              onClick={returnToHome}
            >
              <img
                src="icons8-home.svg"
                alt="home-icon"
                className="icon-landing"
              />
            </div>

            <button className="logout-button">Home</button>
          </div>
          <div className="landingpage-action-icons">
            <div className="app-landing-page-icon-container" onClick={logOut}>
              <img
                src="logout-svgrepo-com.svg"
                alt="logout-icon"
                className="icon-landing"
              />
            </div>

            <button className="logout-button">Log Out</button>
          </div>
        </div>
      </nav>
      <main className="main-container">
        <div className="main-inner-container">
          <div className="section-block-container margin-top-10">
            <div
              className="home-page-content-container"
              style={{ backgroundColor: props.surface_color }}
            >
              <h1
                className="heading-apollo-text"
                style={{ color: props.color }}
              >
                Apollo{" "}
                {current_app === "prediabetes"
                  ? `${`Prediabetes AI`}`
                  : current_app === "liver"
                  ? `${`Ailf`.toUpperCase()}`
                  : current_app.toUpperCase()}
              </h1>
              <div
                className="about-us-container"
                style={{
                  backgroundColor: hover
                    ? props.button_hover_color
                    : props.color,
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <button
                  type="button"
                  className="about-us-button"
                  onClick={() => handleClickScrollTo(aboutRef, "about-icon")}
                >
                  About us
                </button>
              </div>
            </div>
            <div className="home-page-image-container">
              {props.app !== "liver" ? (
                <img
                  src={
                    isTokenExpired(
                      `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-landing-image.jpg?${token}`
                    ) || tokenExpiry
                      ? getImageBlobUrl(`${props.app}-image-2`)
                      : `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-landing-image.jpg?${token}`
                  }
                  className="copd-home-page-image"
                  alt="home-page"
                />

              ) : (
                <img
                  src={
                    isTokenExpired(
                      `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-landing-image.jpeg?${token}`
                    ) || tokenExpiry
                      ? getImageBlobUrl(`${props.app}-image-2`)
                      : `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-landing-image.jpeg?${token}`
                  }
                  className="copd-home-page-image"
                  alt="home-page"
                />
              )}
            </div>
          </div>
          <div className="main-section-inner-block">
            <div
              className="each-individual-section section-border"
              ref={aboutRef}
            >
              <h2 className="each-section-heading">{landingPage[1].heading}</h2>
              <div className="section-block-container">
                <div className="landing-content-container">
                  <ul
                    className="landing-list list-style-type"
                    style={{ backgroundColor: props.surface_color }}
                  >
                    {landingPage[1].points.map((point, index) => {
                      if (typeof point === "object" && point.mainPoint) {
                        return (
                          <li key={index}>
                            <>{point.mainPoint}</>
                            <ul className="sub-points-list-container list-style-type">
                              {point.subPoints.map((subPoint, subIndex) => (
                                <li key={`${index}-${subIndex}`}>{subPoint}</li>
                              ))}
                            </ul>
                          </li>
                        );
                      } else {
                        return (
                          <li key={index} className="">
                            {point}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
                <div className="image-container-block mobile-view-image-container">
                  <>
                    <div className="why-image-container image-1-block">
                      <img
                        src={
                          isTokenExpired(
                            `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-app-page-1.jpg?${token}`
                          ) || tokenExpiry
                            ? getImageBlobUrl(`${props.app}-image-3`)
                            : `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-app-page-1.jpg?${token}`
                        }
                        alt="Healthcare-1"
                        className="why-image"
                      />
                    </div>
                    <div className="why-image-container image-12block">
                      <img
                        src={
                          isTokenExpired(
                            `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-app-page-2.jpg?${token}`
                          ) || tokenExpiry
                            ? getImageBlobUrl(`${props.app}-image-4`)
                            : `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-app-page-2.jpg?${token}`
                        }
                        alt="Healthcare-1"
                        className="why-image"
                      />
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div
              className="each-individual-section section-border"
              ref={validRef}
            >
              <h2 className="each-section-heading">{landingPage[2].heading}</h2>
              <div className="section-block-container align-flex-start">
                <div className="landing-content-container">
                  <ul
                    className="landing-list"
                    style={{ backgroundColor: props.surface_color }}
                  >
                    {landingPage[2].points.map((point, index) => {
                      if (typeof point === "object") {
                        return (
                          <li key={index} className="">
                            <strong>{point.heading}</strong>
                            <p className="landing-list-point">{point.points}</p>
                          </li>
                        );
                      } else {
                        return (
                          <li key={index} className="">
                            {point}
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
                <div className="imterpretation-image-container-block">
                  <img
                    src={
                      isTokenExpired(
                        `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-intepretation-img.jpg?${token}`
                      ) || tokenExpiry
                        ? getImageBlobUrl(`${props.app}-image-5`)
                        : `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/${props.app}-intepretation-img.jpg?${token}`
                    }
                    alt="intepretation"
                    className="intepretation-image"
                    style={{
                      height: isMobile
                        ? "400px"
                        : props.app === "prediabetes"
                        ? "740px"
                        : props.app === "liver"
                        ? "440px"
                        : "505px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="each-individual-section section-border"
              ref={researchRef}
            >
              <h2 className="each-section-heading">{landingPage[3].heading}</h2>
              <div className="section-block-container">
                <ul
                  className="landing-list"
                  style={{ backgroundColor: props.surface_color }}
                >
                  {landingPage[3].points.map((section, sectionIndex) => {
                    if (typeof section === "object") {
                      return (
                        <ul key={sectionIndex} className="">
                          <strong className="inner-sub-heading">
                            {section.heading}
                          </strong>
                          {Array.isArray(section.points) ? (
                            <ul className="section-3-list">
                              {section.points.map((subPoint, subIndex) => (
                                <li key={subIndex}>{subPoint}</li>
                              ))}
                            </ul>
                          ) : (
                            <p className="section3-point">{section.points}</p>
                          )}
                        </ul>
                      );
                    } else return <li key={section}>{section}</li>;
                  })}
                  {landingPage[3].link && (
                    <div className="link-text">
                      <h3>
                        Full text paper available here
                        <img
                          src="point-down.png"
                          alt="down"
                          className="link-point-img"
                        />
                        :
                      </h3>
                      <a
                        href={landingPage[3].link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {landingPage[3].linkText}
                      </a>
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div className="each-individual-section section-border">
              <h2 className="each-section-heading">{landingPage[4].heading}</h2>
              <div className="section-block-container">
                <div className="landing-content-container">
                  <ul
                    className="landing-list list-style-type"
                    style={{ backgroundColor: props.surface_color }}
                  >
                    {landingPage[4].points.map((point) => {
                      return <li key={point}>{point}</li>;
                    })}
                    <p className="certificate-note-point">
                      {landingPage[4].content}
                    </p>
                  </ul>
                </div>
                <div className="cerficate-image-container-block">
                  <div className="cerficate-intepretation-container">
                    <img
                      src={
                        isTokenExpired(
                          `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo_certificate_MD%20763515.png?${token}`
                        ) || tokenExpiry
                          ? getImageBlobUrl(`${props.app}-image-6`)
                          : `https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo_certificate_MD%20763515.png?${token}`
                      }
                      // src="certificate.png"
                      alt="cerficate"
                      className="cerficate-image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div ref={TakeMetoAppRef} className="container take-me-to-app">
              <div className="checkbox-with-text">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    id="loginCheckBox"
                    onChange={OnChangeTermsOfUse}
                  />
                  <span className="custom-checkbox">
                    <img
                      src="checkmark-outline-white.svg"
                      alt="checkmark-outline-white"
                    />
                  </span>
                </div>
                <label htmlFor="loginCheckBox" className="checkbox-text">
                  <span
                    style={{
                      color: takeMeButton ? "red" : "black",
                      fontWeight: takeMeButton ? "600" : "500",
                    }}
                  >
                    I Accept the{" "}
                  </span>
                  <button
                    type="button"
                    className="new-user-link-tag"
                    href="#"
                    onClick={triggerTermsBox}
                  >
                    Terms of use
                  </button>
                </label>
              </div>
              <button
                id="TakeToMeApp"
                type="button"
                onClick={takeMeToAction}
                style={{
                  backgroundColor: takeHover
                    ? "rgb(123, 104, 171)"
                    : "rgb(103, 84, 150)",
                }}
                onMouseEnter={() => setTakeMeHover(true)}
                onMouseLeave={() => setTakeMeHover(false)}
              >
                Take me to App
              </button>
            </div>

            {props.app === "aicvd" && termsofuse && (
              <div id="aicvd-termsBox" className="app-popup-main-container">
                <div className="app-popup-container">
                  <h1 className="app-popup-content-heading">Terms of Use</h1>
                  <div className="app-popup-content-container">
                    <p className="app-popup-text">
                      By agreeing to use the AICVD Application, I have been made
                      aware of the hardware and software requirements that I
                      will need to set up from my side for a smooth and
                      uninterrupted flow of the Application Programming
                      Interface (API). I undertake to abide by all applicable
                      laws and regulations for the safe and ethical delivery of
                      medical services through this platform and shall adhere by
                      the protocols and standard of care as required. The
                      information that I shared with Apollo Hospitals’ AICVD
                      API, is true to my knowledge and belief and I will be
                      responsible for any legal liability that may arise from
                      in-appropriation of any personal or professional
                      information shared or entered in the API. I accept that I
                      shall be solely responsible for the medical advice and
                      recommendations prescribed post consultation with patients
                      via the AICVD App. and any liability that may arise
                      therefrom. I have understood the following disclaimers
                      associated with the use of the AICVD API
                    </p>
                    <ul className="app-popup-list-container">
                      <li>
                        This is not a diagnostic tool and it does not guarantee
                        the accuracy of the result and cannot be independently
                        acted upon.
                      </li>
                      <li>
                        This Risk score and Clinical Algorithm is a general
                        guideline for Physicians. Any additional laboratory
                        investigations, Diagnostic Imaging, Treatment or Patient
                        Education related to lifestyle management is under the
                        Physician’s or Cardiologist’s discretion.
                      </li>
                      <li>
                        To ensure the information in the report is up to date,
                        accurate and correct, Doctor shall be consulted for
                        interpretation of the report. He / She shall also ensure
                        that the entries made in the API are correct.
                      </li>
                      <li>
                        Apollo Hospitals and its Staff does not offer any
                        assurance on the information made available or shall be
                        liable for any loss or damage as the said report is
                        based on the AICVD Cardiac Risk Score without any
                        intervention from their side.
                      </li>
                      <li>
                        By usage of AICVD Cardiac Risk Score, it is deemed that
                        the beneficiary of this service has agreed to get the
                        same done at his/her own risk and further agrees with
                        this disclaimer without any limitation or any clauses or
                        sub-clauses.{" "}
                      </li>
                    </ul>
                    <p className="app-popup-text">
                      Confidentiality: Each Party hereby undertakes that it will
                      not disclose the Confidential Information of the other
                      party, except to its affiliates, employees, and/or agents
                      who need to know such Confidential Information for the
                      purpose of performance of obligations hereunder, provided
                      they have agreed in writing to keep such information
                      confidential and to not use such information for any
                      unauthorized purposes. The recipient of Confidential
                      Information may disclose Confidential Information as
                      required under Applicable Laws, after giving to the
                      discloser of such Confidential Information, reasonable
                      notice and the opportunity to seek confidential treatment
                      by way of a protective order or similar remedies or relief
                      prior to disclosure, in each case if permitted by
                      applicable Law. Except as set forth in this Agreement,
                      neither Party will issue any public announcement.
                    </p>
                  </div>
                  <div className="app-popup-close-container">
                    <button
                      className="app-popup-close-button"
                      type="button"
                      onClick={triggerTermsBox}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
            {props.app === "liver" && termsofuse && (
              <div id="aicvd-termsBox" className="app-popup-main-container">
                <div className="app-popup-container">
                  <h1 className="app-popup-content-heading">Terms of Use</h1>
                  <div className="app-popup-content-container">
                    <p className="app-popup-text">
                      By agreeing to use the AI Liver Fibrosis Application, I
                      have been made aware of the hardware and software
                      requirements that I will need to set up from my side for a
                      smooth and uninterrupted flow of the Application
                      Programming Interface (API). I undertake to abide by all
                      applicable laws and regulations for the safe and ethical
                      delivery of medical services through this platform and
                      shall adhere by the protocols and standard of care as
                      required. The information that I shared with Apollo
                      Hospitals’ AI-LF API, is true to my knowledge and belief
                      and I will be responsible for any legal liability that may
                      arise from in-appropriation of any personal or
                      professional information shared or entered in the API. I
                      accept that I shall be solely responsible for the medical
                      advice and recommendations prescribed post consultation
                      with patients via the AI-LF App. and any liability that
                      may arise therefrom. I have understood the following
                      disclaimers associated with the use of the AILF API
                    </p>
                    <ul className="app-popup-list-container">
                      <li>
                        This is not a diagnostic tool and it does not guarantee
                        the accuracy of the result and cannot be independently
                        acted upon.
                      </li>
                      <li>
                        This Risk score and Clinical Algorithm is a general
                        guideline for Physicians. Any additional laboratory
                        investigations, Diagnostic Imaging, Treatment or Patient
                        Education related to lifestyle management is under the
                        Physician’s or Gastroenterologist discretion.
                      </li>
                      <li>
                        To ensure the information in the report is up to date,
                        accurate and correct, Doctor shall be consulted for
                        interpretation of the report. He / She shall also ensure
                        that the entries made in the API are correct.
                      </li>
                      <li>
                        Apollo Hospitals and its Staff does not offer any
                        assurance on the information made available or shall be
                        liable for any loss or damage as the said report is
                        based on the AI Liver Fibrosis Risk Score without any
                        intervention from their side.{" "}
                      </li>
                      <li>
                        By usage of AI based Liver Fibrosis Risk Score, it is
                        deemed that the beneficiary of this service has agreed
                        to get the same done at his/her own risk and further
                        agrees with this disclaimer without any limitation or
                        any clauses or sub-clauses.
                      </li>
                    </ul>
                    <p className="app-popup-text">
                      Confidentiality: Each Party hereby undertakes that it will
                      not disclose the Confidential Information of the other
                      party, except to its affiliates, employees, and/or agents
                      who need to know such Confidential Information for the
                      purpose of performance of obligations hereunder, provided
                      they have agreed in writing to keep such information
                      confidential and to not use such information for any
                      unauthorized purposes. The recipient of Confidential
                      Information may disclose Confidential Information as
                      required under Applicable Laws, after giving to the
                      discloser of such Confidential Information, reasonable
                      notice and the opportunity to seek confidential treatment
                      by way of a protective order or similar remedies or relief
                      prior to disclosure, in each case if permitted by
                      applicable Law. Except as set forth in this Agreement,
                      neither Party will issue any public announcement.
                    </p>
                  </div>
                  <div className="app-popup-close-container">
                    <button
                      className="app-popup-close-button"
                      type="button"
                      onClick={triggerTermsBox}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
            {props.app === "prediabetes" && termsofuse && (
              <div id="aicvd-termsBox" className="app-popup-main-container">
                <div className="app-popup-container">
                  <h1 className="app-popup-content-heading">Terms of Use</h1>
                  <div className="app-popup-content-container">
                    <p className="app-popup-text">
                      By agreeing to use the Prediabetes AI Application, I have
                      been made aware of the hardware and software requirements
                      that I will need to set up from my side for a smooth and
                      uninterrupted flow of the Application Programming
                      Interface (API). I undertake to abide by all applicable
                      laws and regulations for the safe and ethical delivery of
                      medical services through this platform and shall adhere by
                      the protocols and standard of care as required. The
                      information that I shared with Apollo Hospitals’
                      Prediabetes AI API, is true to my knowledge and belief and
                      I will be responsible for any legal liability that may
                      arise from in-appropriation of any personal or
                      professional information shared or entered in the API. I
                      accept that I shall be solely responsible for the medical
                      advice and recommendations prescribed post consultation
                      with patients via the Prediabetes AI App. and any
                      liability that may arise therefrom. I have understood the
                      following disclaimers associated with the use of the
                      Prediabetes AI API
                    </p>
                    <ul className="app-popup-list-container">
                      <li>
                        This is not a diagnostic tool and it does not guarantee
                        the accuracy of the result and cannot be independently
                        acted upon.
                      </li>
                      <li>
                        This Risk score and Clinical Algorithm is a general
                        guideline for Physicians. Any additional laboratory
                        investigations, Diagnostic Imaging, Treatment or Patient
                        Education related to lifestyle management is under the
                        Physician’s, Endocrinologist or Diabetologist
                        discretion.{" "}
                      </li>
                      <li>
                        To ensure the information in the report is up to date,
                        accurate and correct, Doctor shall be consulted for
                        interpretation of the report. He / She shall also ensure
                        that the entries made in the API are correct.
                      </li>
                      <li>
                        Apollo Hospitals and its Staff does not offer any
                        assurance on the information made available or shall be
                        liable for any loss or damage as the said report is
                        based on the AI based Prediabetes Risk Score without any
                        intervention from their side.
                      </li>
                      <li>
                        By usage of Prediabetes AI Risk Score, it is deemed that
                        the beneficiary of this service has agreed to get the
                        same done at his/her own risk and further agrees with
                        this disclaimer without any limitation or any clauses or
                        sub-clauses.
                      </li>
                    </ul>
                    <p className="app-popup-text">
                      Confidentiality: Each Party hereby undertakes that it will
                      not disclose the Confidential Information of the other
                      party, except to its affiliates, employees, and/or agents
                      who need to know such Confidential Information for the
                      purpose of performance of obligations hereunder, provided
                      they have agreed in writing to keep such information
                      confidential and to not use such information for any
                      unauthorized purposes. The recipient of Confidential
                      Information may disclose Confidential Information as
                      required under Applicable Laws, after giving to the
                      discloser of such Confidential Information, reasonable
                      notice and the opportunity to seek confidential treatment
                      by way of a protective order or similar remedies or relief
                      prior to disclosure, in each case if permitted by
                      applicable Law. Except as set forth in this Agreement,
                      neither Party will issue any public announcement.
                    </p>
                  </div>
                  <div className="app-popup-close-container">
                    <button
                      className="app-popup-close-button"
                      type="button"
                      onClick={triggerTermsBox}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <footer
          id="footer"
          className="container footer-block"
          style={{ backgroundColor: props.surface_color }}
        >
          <p className="footer-inside-element">
            ©2024 Apollo Hospitals Enterprise Ltd, All Rights Reserved
          </p>
        </footer>
      </main>
    </div>
  ) : (
    <div className="app-loading-container">
      <div className="login-loader-container">
        <ColorRing
          height="80"
          width="80"
          ariaLabel="bars-loading"
          visible={true}
          colors={["#a09be4", "#a09be4", "#a09be4", "#a09be4", "#a09be4"]}
        />
      </div>
    </div>
  );
};

export default AppLandingPage;