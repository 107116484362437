"use client"

import { useEffect } from "react"
import ReactDOM from "react-dom"
import "./index.css"

const PopupContainer = ({ isOpen, onClose, title, children }) => {
  useEffect(() => {
    // Prevent scrolling on the body when popup is open
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    // Cleanup function
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isOpen])

  // Handle escape key press
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        onClose()
      }
    }

    if (isOpen) {
      window.addEventListener("keydown", handleEscKey)
    }

    return () => {
      window.removeEventListener("keydown", handleEscKey)
    }
  }, [isOpen, onClose])

  // If not open, don't render anything
  if (!isOpen) return null

  // Create portal to render at the end of the document body
  return ReactDOM.createPortal(
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-container" onClick={(e) => e.stopPropagation()}>
        <div className="popup-header">
          {title && <h2 className="popup-title">{title}</h2>}
          <button className="popup-close-button" onClick={onClose} aria-label="Close">
            ×
          </button>
        </div>
        <div className="popup-content">{children}</div>
      </div>
    </div>,
    document.body,
  )
}

export default PopupContainer

