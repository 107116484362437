import React from "react";
import "./index.css";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export default function NavBar(props) {
  const navigate = useNavigate("/login");

  const HandleClick = (item) => {
    if (props.activeNav === item) return;
    props.changeNavgation(item);
  };

  const logOut = () => {
    Cookies.remove("jwt_token");
    navigate("/login");
  };

  return (
    <div id="Container">
      <div id="logo">
        <img src="logo.png" alt="" />
      </div>
      <ul id="NavItemsContainer">
        {props.NavItems.map((navItem) => {
          return (
            <div className="nav-action-icons">
              <div
                className="icon-container"
                id={navItem === props.activeNav ? "ActiveNavItem" : "inActive"}
              >
                <img
                  src={
                    navItem === "AI Risk Scores & Progression Models​"
                      ? "ai-risk-score.png"
                      : navItem === "AI Augmented Pathways​"
                      ? "path.svg"
                      : "image-signal.svg"
                  }
                  alt="icon"
                  className="nav-icon-landing"
                  onClick={() => HandleClick(navItem)}
                />
              </div>
              <li
                className="NavItems"
                key={navItem}
                onClick={() => HandleClick(navItem)}
              >
                {navItem}
              </li>
            </div>
          );
        })}
      </ul>
      <div className="logoff-button">
        <div className="icon-container">
          <img
            src="logout-svgrepo-com.svg"
            alt="logout-icon"
            className="nav-icon-landing"
            onClick={logOut}
          />
        </div>

        <button className="logout-button">Log Out</button>
      </div>
    </div>
  );
}
