import React from "react";
import "./index.css";
export function ProtocolTable({ data }) {
  return (
    <div className="protocol-container">
      <div className="protocol-header">
        <h3 className="protocol-title">
          AILF DISEASE PREVENTION - AI RECOMMENDED PROTOCOL (FOR DR REVIEW &
          ADVISE)
        </h3>
      </div>
      <div className="protocol-table">
        <div className="protocol-row">
          <div className="protocol-category">LAB INVESTIGATION</div>
          <div className="protocol-details">
            {data.lab_investigation}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">
            DIAGNOSTICS AND IMAGING
          </div>
          <div className="protocol-details">
            {data.diagnostics_imaging}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">REFERRAL</div>
          <div className="protocol-details">
            {data.referral_to_consultant}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">
            TREATMENT GOALS
          </div>
          <div className="protocol-details">
            {data.treatment_goals}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">EDUCATE ON</div>
          <div className="protocol-details">
            {data.educate_on}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">
            TEST FOLLOW UP
          </div>
          <div className="protocol-details">
            {data.tests_follow_up}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtocolTable;
