import React from "react";
import "./index.css";
export function ProtocolTable({ data }) {
  return (
    <div className="protocol-container">
      <div className="protocol-header">
        <h3 className="protocol-title">
          HEART DISEASE PREVENTION - AI RECOMMENDED PROTOCOL (FOR DR REVIEW &
          ADVISE)
        </h3>
      </div>
      <div className="protocol-table">
        <div className="protocol-row">
          <div className="protocol-category">LAB INVESTIGATION</div>
          <div className="protocol-details">
            {data.labInvestigationRecommended &&
              Object.entries(data.labInvestigationRecommended)
                .filter(([key, value]) => value === "Yes")
                .map(([key]) => key)
                .join(", ")}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">DIAGNOSTICS AND IMAGING</div>
          <div className="protocol-details">
            {data.diagnosticsAndImagingRecommended &&
              Object.entries(data.diagnosticsAndImagingRecommended)
                .filter(([key, value]) => value === "Yes")
                .map(([key]) => key)
                .join(", ")}
          </div>
        </div>

        <div className="protocol-row">
          <div className="protocol-category">MEDICATION</div>
          <div className="protocol-details">
            {data.medication &&
              Object.entries(data.medication)
                .filter(([_, value]) => value !== "")
                .map(([key]) => key)
                .join(", ")}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">REFERRAL</div>
          <div className="protocol-details">
            {data.department && data.department} Referral{" "}
            {data.urgency && data.urgency}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">TREATMENT GOALS</div>
          <div className="protocol-details">
            {data.treatment_goals.map((field) => (
              <div className="aicvd-radio-options">
                <strong className="">{field.heading} : </strong>{" "}
                {field.description}
              </div>
            ))}
          </div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">ADVICE</div>
          <div className="protocol-details">
            <p className="disclaimer-item">{data.advice}</p>
            <p className="disclaimer-item">{data.repeatVisitComment}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProtocolTable;
