"use client"

import { useEffect, useState } from "react"
import ShapComponent from "./ShapComponent"
import ProtocolTable from "./ProtocolTable"
import Disclaimer from "./Disclaimer"
import Modal from "./Modal"
import { useMediaQuery } from "../../../hooks/useMobile"
import "./index.css"

const PrediabetesPatientReport = (props) => {
  const [apidata, setAPI] = useState()
  const [shap_values, setShapValues] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMobile = useMediaQuery("(max-width: 768px)")

  useEffect(() => {
    const report = props.data.data
    const shap_values = props.data.data.contributing_factors
    setShapValues(shap_values)
    setAPI({
      activity: report.activity,
      nutrition: report.nutrition,
      referralToConsultant: report.referral_to_consultant,
      risk: report.risk,
      score: report.score,
      testsFollowUp: report.tests_followup,
      treatmentGoals: report.treatment_goal,
      labInvestigation: report.lab_investigation,
      program: report.program,
      educate: report.educate,
    })
  }, [props])

  let riskCardColor = ""

  if (apidata && apidata.risk === "High Risk") riskCardColor = "risk-high-color"
  else if (apidata && apidata.risk === "Moderate Risk") riskCardColor = "risk-medium-color"
  else riskCardColor = "risk-low-color"

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <div>
      {apidata && (
        <div className="new-report-container">
          <div className="new-report-card">
            <h2 className="report-card-title">AI Calculated Risk Score Based on Your Inputs</h2>
            <div className="risk-section">
              <div className="risk-cards">
                <div className="risk-card">
                  <h3 className="risk-card-title">Risk Status</h3>
                  <div className={`${riskCardColor} risk-highlight`}>{apidata.risk}</div>
                </div>
                <div className="risk-card">
                  <h3 className="risk-card-title">AI Score</h3>
                  <p className="risk-value">{apidata.score}%</p>
                </div>
              </div>
            </div>
          </div>

          {/* Desktop view: Show the original card */}
          {!isMobile && (
            <div className="new-report-card">
              <h2 className="report-card-title">Contributing Factor</h2>
              <div className="chart-section">
                <ShapComponent shapValues={shap_values} />
              </div>
            </div>
          )}

          {/* Mobile view: Show a button instead */}
          {isMobile && (
            <button onClick={openModal} className="contributing-factor-button">
              <svg
                className="button-icon"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="3" y1="3" x2="21" y2="3"></line>
                <line x1="3" y1="9" x2="21" y2="9"></line>
                <line x1="3" y1="15" x2="21" y2="15"></line>
                <line x1="3" y1="21" x2="21" y2="21"></line>
              </svg>
              Open Contributing Factor
            </button>
          )}

          {/* Modal for mobile view */}
          <Modal isOpen={isModalOpen} onClose={closeModal} title="Contributing Factors">
            <div className="chart-section">
              <ShapComponent shapValues={shap_values} />
            </div>
          </Modal>

          <div className="new-report-card">
            <h2 className="report-card-title">AI Recommended Protocol</h2>
            <ProtocolTable data={apidata} />
          </div>
          <div className="new-report-card">
            <h2 className="report-card-title">Disclaimer</h2>
            <Disclaimer />
          </div>
        </div>
      )}
    </div>
  )
}

export default PrediabetesPatientReport

