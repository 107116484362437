import React, { useEffect, useState } from "react";
import "./index.css";
import ApolloLogo from "../img/Apollo_logo.svg";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { openDB } from "idb";

export default function Index(props) {
  const { component, appName } = props;
  const navigate = useNavigate("/login");
  const [highlight, setHighlight] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("patient-details")) {
      setHighlight(1);
    } else if (location.pathname.includes("attributes")) {
      setHighlight(2);
    } else if (location.pathname.includes("report")) {
      setHighlight(3);
    }
  }, [location]);

  const initializeDB = async () => {
    const db = await openDB("ImageStore", 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("images")) {
          db.createObjectStore("images", { keyPath: "id" });
        }
      },
    });
    return db;
  };

  const clearImagesOnLogout = async () => {
    try {
      const db = await initializeDB();
      const tx = db.transaction("images", "readwrite");
      const store = tx.objectStore("images");
      await store.clear(); // Clears all records from the 'images' object store
      await tx.done; // Ensure the transaction is completed
    } catch (error) {
      console.error("Error clearing images from IndexedDB:", error);
    }
  };

  const logOut = async () => {
    await clearImagesOnLogout();
    Cookies.remove("jwt_token");
    navigate("/login");
  };

  const returnToHome = () => {
    navigate("/");
  };

  return (
    <>
      <nav id="navBar_PDPage" style={{ backgroundColor: props.surface_color }}>
        <div id="ApolloLogoBox_PDPage">
          <img src={ApolloLogo} alt="" />
        </div>
        <h1 id="titleNameOfApplication_PDPage" style={{ color: props.color }}>
          {appName === "aicvd"
            ? "AICVD Report"
            : appName === "prediabetes"
            ? "Prediabetes Report"
            : appName === "liver"
            ? "AILF Report"
            : appName}
        </h1>
      </nav>
      <main id="bodyContentContainer">
        <nav
          className="outframe-heading-bar-container"
          style={{ backgroundColor: props.surface_color }}
        >
          <ul className="list-of-redirect-elements">
            <div className="landingpage-action-icons">
              <div
                id="about-icon"
                className="app-landing-page-icon-container"
                style={{
                  backgroundColor:
                    highlight === 1 ? props.surface_highlight_color : "",
                }}
              >
                <img
                  src="folder.svg"
                  alt="about-icon"
                  className="icon-landing"
                />
              </div>
              <li className="nav-link-buttons">Details</li>
            </div>
            <div className="landingpage-action-icons">
              <div
                id="research-icon"
                className="app-landing-page-icon-container"
                style={{
                  backgroundColor:
                    highlight === 3 ? props.surface_highlight_color : "",
                }}
              >
                <img
                  src="chart.jpg"
                  alt="research-icon"
                  className="icon-landing"
                />
              </div>
              <li className="nav-link-buttons">Report</li>
            </div>
          </ul>
          <div className="logout-button-container-block">
            <div className="landingpage-action-icons">
              <div
                className="app-landing-page-icon-container"
                onClick={returnToHome}
              >
                <img
                  src="icons8-home.svg"
                  alt="home-icon"
                  className="icon-landing"
                />
              </div>

              <button className="logout-button">Home</button>
            </div>
            <div className="landingpage-action-icons">
              <div className="app-landing-page-icon-container" onClick={logOut}>
                <img
                  src="logout-svgrepo-com.svg"
                  alt="logout-icon"
                  className="icon-landing"
                />
              </div>

              <button className="logout-button">Log Out</button>
            </div>
          </div>
        </nav>
        <div id="rightContentBlock">{component}</div>
      </main>
    </>
  );
}
