import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import OuterFrame from "../../common/OuterFrame";
import { Oval } from "react-loader-spinner";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SidebarOverlay from "../../Sidebar";
import ConcentForm from "../../common/PaitentConcent";
import {
  faUser,
  faRuler,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faIdBadge,
  faBirthdayCake,
  faVenusMars,
  faWeight,
  faBalanceScale,
  faHeartbeat,
  faArrowUp,
  faArrowDown,
  faLungs,
} from "@fortawesome/free-solid-svg-icons";
import PopupContainer from "../../PopupContainer";

const AICVDDetailsPage = (props) => {
  const navigate = useNavigate();
  const [loaderstatus, setLoaderStatus] = useState(true); // Start with true
  const [isDataReady, setIsDataReady] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [formData, setFormData] = useState({});
  const [warningMessage, setWarningMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [activeId, setActiveId] = useState(null);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const isEmptyValue = (value) => {
    return value === undefined || value === null || value === "";
  };

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    patientInfo.Patient_Details?.forEach((field) => {
      if (field.required === "yes" && isEmptyValue(formData[field.key])) {
        errors[field.key] = "This field is required";
        isValid = false;
      }
    });

    patientInfo.Attributes?.[0]["Physical Attributes"]?.forEach((field) => {
      if (field.required === "yes" && isEmptyValue(formData[field.key])) {
        errors[field.key] = "This field is required";
        isValid = false;
      }
    });

    patientInfo.Attributes?.[1]["Medical History"]?.forEach((field) => {
      if (field.required === "yes" && isEmptyValue(formData[field.key])) {
        errors[field.key] = "This field is required";
        isValid = false;
      }
    });

    patientInfo.Attributes?.[3]["Lab History"]?.forEach((field) => {
      if (field.required === "yes" && isEmptyValue(formData[field.key])) {
        errors[field.key] = "This field is required";
        isValid = false;
      }
    });

    setValidationErrors(errors);
    return isValid;
  };

  const toggleSidebar = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setWarningMessage("");
    if (!validateForm()) {
      setWarningMessage("Please fill in all required fields");
      return;
    } else {
      props.form_details && props.form_details(formData);
      navigate(`/aicvd/report`);
    }
  };

  const findFieldByKey = (key) => {
    // Search in Patient Details
    const patientField = patientInfo.Patient_Details?.find(
      (f) => f.key === key
    );
    if (patientField) return patientField;

    // Search in Physical Attributes
    const physicalField = patientInfo.Attributes?.[0][
      "Physical Attributes"
    ]?.find((f) => f.key === key);
    if (physicalField) return physicalField;

    // Search in Medical History
    const medicalField = patientInfo.Attributes?.[1]["Medical History"]?.find(
      (f) => f.key === key
    );
    if (medicalField) return medicalField;

    // Search in Lab History
    const labField = patientInfo.Attributes?.[3]["Lab History"]?.find(
      (f) => f.key === key
    );
    if (labField) return labField;

    // If no field is found
    return null;
  };

  const handleInputChange = (key, value) => {
    const field = findFieldByKey(key);

    if (field?.ranges && value !== "") {
      const numValue = Number(value);
      if (numValue < field.ranges[0] || numValue > field.ranges[1]) {
        setValidationErrors((prev) => ({
          ...prev,
          [key]: `Value must be between ${field.ranges[0]} and ${field.ranges[1]}`,
        }));
      } else {
        setValidationErrors((prev) => {
          const newErrors = { ...prev };
          delete newErrors[key];
          return newErrors;
        });
      }
    }

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    const initializeData = async () => {
      setLoaderStatus(true);
      try {
        const savedContent = JSON.parse(localStorage.getItem("fields"));

        if (savedContent) {
          setPatientInfo(savedContent);
          setIsDataReady(true);
        }
      } catch (error) {
      } finally {
        // Give a slight delay
        // Give a slight delay to ensure state updates are processed
        setTimeout(() => {
          setLoaderStatus(false);
        }, 500);
      }
    };

    initializeData();
  }, [props.app]);

  const calculateBMI = useCallback((height, weight) => {
    if (height && weight) {
      let bmi = weight / Math.pow(height * 0.01, 2);
      let decimal = bmi - Math.floor(bmi);
      bmi = Math.floor(bmi) + Math.floor(decimal * 100) / 100;
      return bmi;
    }
    return null;
  }, []);

  useEffect(() => {
    const height = formData.height;
    const weight = formData.weight;
    if (height && weight) {
      const bmi = calculateBMI(height, weight);
      setFormData((prevValues) => {
        if (prevValues.BMI !== bmi) {
          return {
            ...prevValues,
            BMI: bmi,
          };
        }
        return prevValues;
      });
    }
  }, [formData.height, formData.weight, calculateBMI]);

  const handleOptionChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const renderAttributes = (attributes, section) => {
    return attributes.map((attribute, index) => (
      <div className="form-field-container">
        <div key={index} className="form-field">
          <FontAwesomeIcon
            icon={getIconForField(attribute.name)}
            className="field-icon"
          />
          <>
            <input
              type="text"
              placeholder=""
              value={formData[attribute.key] || ""}
              onFocus={(e) => {
                handleFieldFocus(attribute.key);
                e.target.placeholder = getPlaceholderText(attribute);
              }}
              onBlur={(e) => {
                e.target.placeholder = "";
              }}
              onChange={(e) => handleInputChange(attribute.key, e.target.value)}
              readOnly={attribute.key === "bmi" || attribute.key === "BMI"} // Make BMI field readonly
              className={`${validationErrors[attribute.key] ? "error" : ""} 
                       ${
                         attribute.key === "bmi" || attribute.key === "BMI"
                           ? "readonly"
                           : ""
                       }`}
            />
            <label>
              {attribute.name}{" "}
              {attribute.required === "yes" && (
                <span className="required">*</span>
              )}
            </label>
            {attribute.description && attribute.description !== "" && (
              <span>
                <button
                  type="button"
                  onClick={() => toggleSidebar(attribute.name)}
                  className="description-bar"
                >
                  i
                </button>
                {activeId === attribute.name && (
                  <SidebarOverlay
                    id={attribute.name}
                    description={attribute.description}
                    activeId={activeId}
                    name={attribute.name}
                    setActiveId={setActiveId}
                    toggleSidebar={toggleSidebar}
                  />
                )}
              </span>
            )}
          </>
        </div>
        {validationErrors[attribute.key] && (
          <span className="error-message">
            {validationErrors[attribute.key]}
          </span>
        )}
      </div>
    ));
  };

  const renderHealthAttributes = (HeartHealth) => {
    const selectItems = HeartHealth?.filter((item) => item.type === "select");
    const radioItems = HeartHealth?.filter((item) => item.type === "radio");
    const numberItems = HeartHealth?.filter((item) => item.type === "number");

    return (
      <div className="lifestyle-form">
        <div className="lifestyle-form-grid">
          {radioItems?.map((item, index) => (
            <div key={index} className="lifestyle-card">
              <p className="lifestyle-title">
                {item.name}
                {item.required === "yes" && <span className="required">*</span>}
                {item.description && item.description !== "" && (
                  <span>
                    <button
                      type="button"
                      onClick={() => toggleSidebar(item.name)}
                      className="description-bar"
                    >
                      i
                    </button>
                    {activeId === item.name && (
                      <SidebarOverlay
                        id={item.name}
                        description={item.description}
                        activeId={activeId}
                        name={item.name}
                        setActiveId={setActiveId}
                        toggleSidebar={toggleSidebar}
                      />
                    )}
                  </span>
                )}
              </p>

              <div className="radio-group">
                <select
                  name={item.key}
                  value={formData[item.key] || ""}
                  onChange={(e) => handleOptionChange(item.key, e.target.value)}
                  className={validationErrors[item.key] ? "error" : ""}
                >
                  <option value="">Select</option>
                  {item.options.map((option, optIndex) => (
                    <option key={optIndex} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {validationErrors[item.key] && (
                  <span className="error-message">
                    {validationErrors[item.key]}
                  </span>
                )}
              </div>
            </div>
          ))}
          {selectItems?.map((item, index) => (
            <div key={index} className="lifestyle-card">
              <p className="lifestyle-title">
                {item.name}
                {item.required === "yes" && <span className="required">*</span>}
                {item.description && item.description !== "" && (
                  <span>
                    <button
                      type="button"
                      onClick={() => toggleSidebar(item.name)}
                      className="description-bar"
                    >
                      i
                    </button>
                    {activeId === item.name && (
                      <SidebarOverlay
                        id={item.name}
                        description={item.description}
                        activeId={activeId}
                        name={item.name}
                        setActiveId={setActiveId}
                        toggleSidebar={toggleSidebar}
                      />
                    )}
                  </span>
                )}
              </p>

              <div className="radio-group">
                <select
                  name={item.key}
                  value={formData[item.key] || ""}
                  onChange={(e) => handleOptionChange(item.key, e.target.value)}
                  className={validationErrors[item.key] ? "error" : ""}
                >
                  <option value="">Select</option>
                  {item.options.map((option, optIndex) => (
                    <option key={optIndex} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {validationErrors[item.key] && (
                  <span className="error-message">
                    {validationErrors[item.key]}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="lifestyle-number-grid">
          {numberItems?.map((item, index) => (
            <div key={index} className="form-field-container">
              <div className="form-field">
                <FontAwesomeIcon
                  icon={getIconForField(item.name)}
                  className="field-icon"
                />
                <input
                  type="number"
                  placeholder=""
                  value={formData[item.key] || ""}
                  onFocus={(e) => {
                    handleFieldFocus(item.key);
                    e.target.placeholder = getPlaceholderText(item);
                  }}
                  onBlur={(e) => {
                    e.target.placeholder = "";
                  }}
                  onChange={(e) => handleInputChange(item.key, e.target.value)}
                  className={validationErrors[item.key] ? "error" : ""}
                />
                <label>
                  {item.name}
                  {item.required === "yes" && (
                    <span className="required">*</span>
                  )}
                  {item.units && <span className="unit">({item.units})</span>}
                </label>
              </div>
              {validationErrors[item.key] && (
                <span className="error-message">
                  {validationErrors[item.key]}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPsychologicalStress = (attributes) => {
    return attributes.map((item, index) => (
      <div key={index} className="lifestyle-card">
        <p className="lifestyle-title">
          {item.name}
          {item.required === "yes" && <span className="required">*</span>}
          {item.description && item.description !== "" && (
            <span>
              <button
                type="button"
                onClick={() => toggleSidebar(item.name)}
                className="description-bar"
              >
                i
              </button>
              {activeId === item.name && (
                <SidebarOverlay
                  id={item.name}
                  description={item.description}
                  activeId={activeId}
                  name={item.name}
                  setActiveId={setActiveId}
                  toggleSidebar={toggleSidebar}
                />
              )}
            </span>
          )}
        </p>

        <div className="radio-group">
          <select
            name={item.key}
            value={formData[item.key] || ""}
            onChange={(e) => handleOptionChange(item.key, e.target.value)}
            className={validationErrors[item.key] ? "error" : ""}
          >
            <option value="">Select</option>
            {item.options.map((option, optIndex) => (
              <option key={optIndex} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
          {validationErrors[item.key] && (
            <span className="error-message">{validationErrors[item.key]}</span>
          )}
        </div>
      </div>
    ));
  };
  const renderLifeStyle = (attributes) => {
    return (
      <div className="lifestyle-aicvd-form-grid">
        {attributes.map((item, index) => (
          <div key={index} className="lifestyle-card">
            <p className="lifestyle-title">
              {item.name}
              {item.required === "yes" && <span className="required">*</span>}
              {item.description && item.description !== "" && (
                <span>
                  <button
                    type="button"
                    onClick={() => toggleSidebar(item.name)}
                    className="description-bar"
                  >
                    i
                  </button>
                  {activeId === item.name && (
                    <SidebarOverlay
                      id={item.name}
                      description={item.description}
                      activeId={activeId}
                      name={item.name}
                      setActiveId={setActiveId}
                      toggleSidebar={toggleSidebar}
                    />
                  )}
                </span>
              )}
            </p>
            {item.options &&
              item.options.map((option, optionIndex) => (
                <div
                  className="sub-option-radio aicvd-radio-options"
                  key={optionIndex}
                >
                  <input
                    type="radio"
                    className="radio-button"
                    id={`${item.key}-${option.value}`}
                    name={`${item.key}`}
                    value={option.value}
                    onChange={(e) => handleOptionChange(item.key, option.value)}
                  />
                  <span className="c-radio"></span>
                  <label
                    htmlFor={`${item.key}-${option.value}`}
                    className="sub-option-radio-label"
                  >
                    {option.name}
                  </label>
                </div>
              ))}
          </div>
        ))}
      </div>
    );
  };

  const renderMedicalHistory = (attributes) => {
    return attributes.map((item, index) => (
      <div className="form-field-container">
        <div key={index} className="lifestyle-card">
          <p className="lifestyle-title">
            {item.name}
            {item.required === "yes" && <span className="required">*</span>}
            {item.description && item.description !== "" && (
              <span>
                <button
                  type="button"
                  onClick={() => toggleSidebar(item.name)}
                  className="description-bar"
                >
                  i
                </button>
                {activeId === item.name && (
                  <SidebarOverlay
                    id={item.name}
                    description={item.description}
                    activeId={activeId}
                    name={item.name}
                    setActiveId={setActiveId}
                    toggleSidebar={toggleSidebar}
                  />
                )}
              </span>
            )}
          </p>

          <div className="radio-group">
            <select
              name={item.key}
              value={formData[item.key] || ""}
              onChange={(e) => handleOptionChange(item.key, e.target.value)}
              className={validationErrors[item.key] ? "error" : ""}
            >
              <option value="">Select</option>
              {item.options.map((option, optIndex) => (
                <option key={optIndex} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
            {validationErrors[item.key] && (
              <span className="error-message">
                {validationErrors[item.key]}
              </span>
            )}
          </div>
        </div>
      </div>
    ));
  };

  const getIconForField = (fieldName) => {
    switch (fieldName.toLowerCase()) {
      case "email":
        return faEnvelope;
      case "location":
        return faMapMarkerAlt;
      case "phone":
        return faPhone;
      case "uhid":
        return faIdBadge;
      case "name":
        return faUser;
      case "age":
        return faBirthdayCake;
      case "gender":
        return faVenusMars;
      case "height":
        return faRuler;
      case "weight":
        return faWeight;
      case "bmi":
        return faBalanceScale;
      case "heart rate": // Added for Heart Rate
        return faHeartbeat;
      case "systolic bp": // Added for Systolic Blood Pressure
        return faArrowUp;
      case "diastolic bp": // Added for Diastolic Blood Pressure
        return faArrowDown;
      case "respiration rate": // Added for Respiration Rate
        return faLungs;
      default:
        return faUser;
    }
  };

  const handleFieldFocus = (key) => {
    if (validationErrors[key]) {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[key];
        return newErrors;
      });
    }
  };

  const getPlaceholderText = (field) => {
    if (field.ranges) {
      return `${field.ranges[0]} - ${field.ranges[1]}`;
    }
    return "";
  };

  const handleCheckboxChange = (checkboxId) => {
    if (checkboxId === 1) {
      setIsChecked1((prev) => !prev);
    }
  };

  const openPopup = () => setIsPopupOpen(true);

  const closePopup = () => setIsPopupOpen(false);

  return (
    <OuterFrame
      hightlight="2"
      appName={"AICVD SCORE"}
      color={props.color}
      secondaryColor={props.secondaryColor}
      surface_color={props.surface_color}
      surface_highlight_color={props.surface_highlight_color}
      surface_hover_color={props.surface_hover_color}
      button_hover_color={props.button_hover_color}
      component={
        loaderstatus || !isDataReady ? (
          <div className="loader-container">
            <Oval
              height={80}
              width={80}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="medical-form">
            <div className="form-sections">
              <section className="basic-info">
                <h2 className="patient-medical-info-heading">
                  Basic Information
                </h2>
                <div className="form-grid">
                  {patientInfo?.Patient_Details.map((patient, index) => (
                    <div key={index} className="form-field-container">
                      <div className="form-field">
                        <FontAwesomeIcon
                          icon={getIconForField(patient.name)}
                          className="field-icon"
                        />
                        {patient.key === "gender" ? (
                          <select
                            value={formData.gender || ""}
                            onChange={(e) =>
                              handleInputChange("gender", e.target.value)
                            }
                            onFocus={() => handleFieldFocus(patient.key)}
                            className="gender-select"
                          >
                            <option value="" disabled>
                              Select Gender
                            </option>
                            {patient.options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <>
                            <input
                              type="text"
                              placeholder=""
                              value={formData[patient.key] || ""}
                              onFocus={(e) => {
                                handleFieldFocus(patient.key);
                                e.target.placeholder =
                                  getPlaceholderText(patient);
                              }}
                              onBlur={(e) => {
                                e.target.placeholder = "";
                              }}
                              onChange={(e) =>
                                handleInputChange(patient.key, e.target.value)
                              }
                            />
                            <label>
                              {patient.name}{" "}
                              {patient.required === "yes" && (
                                <span className="required">*</span>
                              )}
                            </label>
                          </>
                        )}
                      </div>
                      {validationErrors[patient.key] && (
                        <span className="error-message">
                          {validationErrors[patient.key]}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </section>
              <section className="physical-attributes">
                <h2 className="patient-medical-info-heading">
                  Physical Attributes
                </h2>
                <div className="form-grid">
                  {renderAttributes(
                    patientInfo?.Attributes[0]["Physical Attributes"]
                  )}
                </div>
              </section>
              <section className="physical-attributes">
                <h2 className="patient-medical-info-heading">
                  Heart Health Attributes
                </h2>
                {renderHealthAttributes(
                  patientInfo?.Attributes[1]["Heart Health attributes"]
                )}
              </section>
              <section className="physical-attributes">
                <h2 className="patient-medical-info-heading">
                  Psychological Attributes
                </h2>
                {renderPsychologicalStress(
                  patientInfo.Attributes[2]["Psychological Attributes"]
                )}
              </section>
              <section className="physical-attributes">
                <h2 className="patient-medical-info-heading">Lifestyle</h2>
                {renderLifeStyle(patientInfo.Attributes[3]["Lifestyle"])}
              </section>
              <section className="physical-attributes">
                <h2 className="patient-medical-info-heading">
                  Medical History
                </h2>
                <div className="medical-form-grid">
                  {renderMedicalHistory(
                    patientInfo?.Attributes[4]["Medical History"]
                  )}
                </div>
              </section>
              <div className="showConcentDiv">
                <span className="DocLogoForConcentButton" onClick={openPopup}>
                  <img
                    src="doc-icon.svg"
                    className="consent-icon"
                    alt="consent-icon"
                  />
                </span>
                <button
                  className="showConcentButton"
                  onClick={openPopup}
                  type="button"
                >
                  PATIENT INFORMATION SHEET AND INFORMED CONSENT
                </button>
              </div>
              <div className="checkBoxForTC">
                <div className="checkbox-for-patient-consent">
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      id="loginCheckBox1"
                      checked={isChecked1}
                      onChange={() => handleCheckboxChange(1)}
                    />
                    <span className="custom-checkbox">
                      <img
                        src="checkmark-outline-white.svg"
                        alt="checkmark-outline-white"
                      />
                    </span>
                  </div>
                  <label
                    htmlFor="loginCheckBox1"
                    className="patient-consent-label-content"
                  >
                    Patient has read and understood the information sheet, had
                    the opportunity to ask questions, and agreed to participate
                    in the NCD Risk Scores at Apollo Hospitals.
                  </label>
                </div>
              </div>
            </div>
            {warningMessage !== "" ? (
              <p className="all-fields-warning-msg">{warningMessage}</p>
            ) : null}
            <div className="submit-button-container">
              <button type="submit" className="submit-button">
                Submit
              </button>
            </div>

            <PopupContainer
              isOpen={isPopupOpen}
              onClose={closePopup}
              title="STUDY PARTICIPANT INFORMATION SHEET"
            >
              <ConcentForm data={patientInfo.patient_consent} />
            </PopupContainer>
          </form>
        )
      }
    />
  );
};

export default AICVDDetailsPage;
