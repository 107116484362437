import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AppLandingPage from "../../common/AppLandingPage";
import Report from "../../common/Report";
import { FieldsProvider } from "../../../context/FieldsContext";
import LiverPdfDetails from "../../pdfReports/LiverReport";
import {
  getImagesFromDB,
  fetchAndStoreImages,
} from "../../../utils/imageUtils";
import DetailsPage from "../../LiverComponent/DetailsPage";

const LiverRoutes = (props) => {
  const LIVER_LANDING_URL =
    "https://clinical-server-apis.azurewebsites.net/fields/liver";
  const LIVER_REPORT_URL =
    "https://clinical-server-apis.azurewebsites.net/liver";

  const app = "liver";
  const color = "rgb(0, 126, 0)";
  const secondaryColor = "#00b300";
  const button_hover_color = "#00b300";

  const surface_color = "rgb(238 245 240)";
  const surface_hover_color = "#e8e0e8";
  const surface_highlight_color = "#dcdaf5";

  const [formDetails, setFormDetails] = useState({});
  const [PdfInfo, setPDFDetails] = useState({});
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState([]);

  const form_details = (props) => {
    const details = {
      patient_id: props.patient_id,
      name: props.name,
      age: props.age,
      gender: props.gender,
      location: props.location,
      email: props.email,
      phone: props.phone,
      height: parseInt(props.height),
      weight: parseInt(props.weight),
      bmi: props.BMI,
      albumin: props.albumin,
      alkphos_alkaline_phosphotase: props.alkphos_alkaline_phosphotase,
      cholesterol: props.cholesterol,
      ldl_cholesterol: props.ldl_cholesterol,
      platelet_count: props.platelet_count,
      sgot: props.sgot,
      sgpt: props.sgpt,
      total_bilirubin: props.total_bilirubin,
      total_protiens: props.total_protiens,
      fatty_liver: props.fatty_liver,
      diabetes: props.diabetes !== "No" ? "yes" : "no",
      diabetes_test_date: props.diabetes_test_date,
      diabetes_test_value: props.diabetes_test_value,
      fbs_test_date: props.fbs_test_date,
      fbs_test_value: props.fbs_test_value,
      hba1c_test_date: props.hba1c_test_date,
      hba1c_test_value: props.hba1c_test_value,
      homa_ir_test_date: props.homa_ir_test_date,
      homa_ir_test_value: props.homa_ir_test_value,
      liver_disease:
        props.liver_disease === "" ||
        props.liver_disease === null ||
        props.liver_disease === undefined
          ? 0
          : parseFloat(props.liver_disease),
      hypertension: props.hypertension !== "No" ? "yes" : "no",
      hypertension_list: props.hypertension_list,
      hypertension_second_list: props.hypertension_second_list,
      hepatitis: props.hepatitis,
      hav_igm: props.hepatitis?.includes("HAV IgM") ? "HAV IgM" : "",
      hav_igm_no_years: props.hav_igm_no_years,
      anti_hcv: props.hepatitis?.includes("anti-HCV") ? "anti-HCV" : "",
      anti_hcv_no_years: props.anti_hcv_no_years,
      hev_igm: props.hepatitis?.includes("HEV IgM") ? "HEV IgM" : "",
      hev_igm_no_years: props.hev_igm_no_years,
      hbsag: props.hepatitis?.includes("HBsAg") ? "HBsAg" : "",
      hbsag_no_years: props.hbsag_no_years,
      alcohol: props.alcohol !== "No" ? "yes" : "no",
      alcohol_sub: props.alcohol_sub,
      diabetes_condition: props.diabetes,
      dyslipidemia: props.dyslipidemia,
      dyslipidemia_list: props.dyslipidemia_list,
      dyslipidemia_second_list: props.dyslipidemia_second_list,
      dyslipidemia_third_list: props.dyslipidemia_third_list,
      liver_disease_history: props.liver_disease_history,
    };

    const removeEmptyValues = (obj) => {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([_, value]) => value !== "" && value !== null && value !== undefined
        )
      );
    };

    const pdf_details = {
      basic_info: {
        UHID: props.patient_id,
        Name: props.name,
        Age: props.age,
        Gender: props.gender,
        Location: props.location,
        Mobile: props.phone,
      },
      "Vital Signs": {
        Height: props.height,
        Weight: parseInt(props.weight),
        BMI: parseFloat(props.BMI),
      },
      "Medical history": removeEmptyValues({
        Diabetes: props.diabetes,
        Hypertension: props.hypertension,
        "Liver Disease History": props.liver_disease_history,
        Dyslipidaemia: props.dyslipidemia,
        "Hav igm": props.hepatitis?.includes("HAV IgM") ? "HAV IgM" : "",
        "Anti Hcv": props.hepatitis?.includes("anti-HCV") ? "anti-HCV" : "",
        "Hev Igm": props.hepatitis?.includes("HEV IgM") ? "HEV IgM" : "",
        Hbsag: props.hepatitis?.includes("HBsAg") ? "HBsAg" : "",
        Hepatitis: props.hepatitis?.includes("None") ? props.hepatitis : null,
      }),

      "Life Style": {
        "Liver Disease":
          props.liver_disease !== "" ? parseFloat(props.liver_disease) : 0,
        Alcohol: props.alcohol,
        fatty_liver: props.fatty_liver,
      },
      "Lab History": {
        Albumin: props.albumin,
        "Alkphos Alkaline Phosphotase": props.alkphos_alkaline_phosphotase,
        Cholesterol: props.cholesterol,
        "Ldl Cholesterol": props.ldl_cholesterol,
        "Platelet Count": props.platelet_count,
        Sgot: props.sgot,
        Sgpt: props.sgpt,
        "Total Bilirubin": props.total_bilirubin,
        "Total Protiens": props.total_protiens,
      },
    };
    setFormDetails(details);
    setPDFDetails(pdf_details);
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        const storedImages = await getImagesFromDB(app);
        if (storedImages.length > 0) {
          setImages(storedImages);
        } else {
          const urls = [
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo-logo.png",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/liver-landing-image-edited.jpeg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/liver-app-page-1.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/liver-app-page-2.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/intepretation-img-2.jpg",
            "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo_certificate_MD%20763515.png",
          ];
          await fetchAndStoreImages(app, urls, setImages);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      } finally {
        setLoading(false);
      }
    };
    loadImages();
  }, []);

  return (
    <FieldsProvider>
      <Routes>
        <Route
          path="/"
          element={
            <AppLandingPage
              LANDING_URL={LIVER_LANDING_URL}
              app={app}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              secondaryColor={secondaryColor}
              button_hover_color={button_hover_color}
              images={images}
              loading={loading}
            />
          }
        />

        <Route
          path="patient-details"
          element={
            <DetailsPage
              app={app}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
              form_details={form_details}
            />
          }
        />
        <Route
          path="report"
          element={
            <Report
              app={app}
              formDetails={formDetails}
              REPORT_URL={LIVER_REPORT_URL}
              surface_color={surface_color}
              surface_highlight_color={surface_highlight_color}
              surface_hover_color={surface_hover_color}
              color={color}
              button_hover_color={button_hover_color}
              secondaryColor={secondaryColor}
            />
          }
        />
        <Route
          path="pdfreport"
          element={
            <LiverPdfDetails app={app} PdfInfo={PdfInfo} color={color} />
          }
        />
      </Routes>
    </FieldsProvider>
  );
};

export default LiverRoutes;
