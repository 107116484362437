import React from "react";
import "./index.css";
export function ProtocolTable({ data }) {
  return (
    <div className="protocol-container">
      <div className="protocol-header">
        <h3 className="protocol-title">
          PREDIBETES AI DISEASE PREVENTION - AI RECOMMENDED PROTOCOL (FOR DR
          REVIEW & ADVISE)
        </h3>
      </div>
      <div className="protocol-table">
        <div className="protocol-row">
          <div className="protocol-category">LAB INVESTIGATION</div>
          <div className="protocol-details">{data.labInvestigation}</div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">NUTRITION</div>
          <div className="protocol-details">{data.nutrition}</div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">PROGRAM</div>
          <div className="protocol-details">{data.program}</div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">ACTIVITY</div>
          <div className="protocol-details">{data.activity}</div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">REFERRAL</div>
          <div className="protocol-details">{data.referralToConsultant}</div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">TREATMENT GOALS</div>
          <div className="protocol-details">{data.treatmentGoals}</div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">EDUCATE ON</div>
          <div className="protocol-details">{data.educate}</div>
        </div>
        <div className="protocol-row">
          <div className="protocol-category">TEST FOLLOW UP</div>
          <div className="protocol-details">{data.testsFollowUp}</div>
        </div>
      </div>
    </div>
  );
}

export default ProtocolTable;
