import { Component } from "react"
import CanvasJSReact from "@canvasjs/react-charts"
import "./index.css"

// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart

class Shap extends Component {
  render() {
    const { shapValues, keyProp } = this.props

    if (!shapValues) {
      return null // Return early if no data
    }

    // Sorting and filtering logic from the first component
    const keyValueArray = Object.entries(shapValues)

    // Sort by absolute value (magnitude) in descending order
    const sortedArray = keyValueArray.sort((a, b) => Math.abs(Number(b[1])) - Math.abs(Number(a[1])))

    // Take only the top 10 parameters
    const topParameters = sortedArray.slice(0, 10)

    // Filter out values that are exactly 0
    const filteredParameters = topParameters.filter(([_, value]) => Number.parseFloat(value) !== 0)

    // Create dataPoints from filtered parameters
    const dataPoints = filteredParameters.map(([label, value]) => ({
      y: Number.parseFloat(value),
      label: label,
      color: Number.parseFloat(value) <= 0 ? "#FF024F" : "#008AFB",
    }))

    // Chart options with responsive design from the second component
    const options = {
      animationEnabled: true,
      theme: "light2",
      height: 410,
      axisX: {
        reversed: true,
        labelFontSize: window.innerWidth <= 576 ? 10 : 12,
        labelFontWeight: "bold", // Added font weight for thicker labels
        interval: 1,
        labelWrap: true,
        labelMaxWidth: window.innerWidth <= 576 ? 80 : 120,
        labelAngle: window.innerWidth <= 576 ? -45 : 0,
        lineThickness: 0.5, // Increased line thickness
        tickThickness: 1, // Increased tick thickness
      },
      axisY: {
        gridThickness: 0,
        labelWrap: true,
        labelFontSize: window.innerWidth <= 576 ? 10 : 12,
        labelFontWeight: "bold", // Added font weight for thicker labels
        lineThickness: 0.5, // Increased from 1 to 2
        tickThickness: 1, // Added tick thickness
      },
      toolTip: {
        fontSize: window.innerWidth <= 576 ? 10 : 12,
        fontWeight: "bold", // Added font weight for tooltip
      },
      responsive: true,
      maintainAspectRatio: false,
      data: [
        {
          type: "bar",
          dataPoints: dataPoints,
          indexLabelFontWeight: "bold", // Added for data labels if they're shown
        },
      ],
    }

    return (
      <div className="shap-main-plot" key={keyProp}>
        <CanvasJSChart options={options} />
      </div>
    )
  }
}

export default Shap

