// /src/components/common/PdfDetails.js
import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  forwardRef,
} from "react";
import Cookies from "js-cookie";
import PatientContext from "../../../context/PatientContext";
import "./index.css";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import ProtocolTable from "./ProtocolTable";
import Disclaimer from "./Disclaimer";

const PrintPdfDetails = forwardRef((props, ref) => {
  const [patientInfo, setPatientInfo] = useState(props.PdfInfo);
  const { patientDetails } = useContext(PatientContext);
  const { contextPatientReport } = useContext(PatientContext);
  const [apidata, setAPI] = useState();

  useEffect(() => {
    setPatientInfo(props.PdfInfo);
    const report = contextPatientReport.data.data;
    setAPI(report);
  }, [props.PdfInfo, contextPatientReport, patientDetails]);

  const renderCurrentDate = () => {
    const date = new Date();
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  };

  let riskCardColor = "";

  if (apidata && apidata.risk === "High Risk")
    riskCardColor = "risk-card-bg-red";
  else if (apidata && apidata.risk === "Moderate Risk")
    riskCardColor = "risk-card-bg-yellow";
  else riskCardColor = "risk-card-bg-green";

  const loginDetails = JSON.parse(Cookies.get("login_details"));

  return (
    <div className="pdf-container" ref={ref}>
      <div className="page">
        <div className="page-header">
          <h1 className="header-heading">Participant Report</h1>
          <img
            className="header-log"
            src="./apollo-white-logo.png"
            alt="apollo-logo"
          />
        </div>
        <div className="pdf-content-container">
          <ul className="pdf-user-info-container">
            {Object.entries(patientInfo?.basic_info).map(([key, value]) => (
              <li key={key}>
                <p className="pdf-user-info-heading">{key}</p>:
                <p className="pdf-user-info-value">{value}</p>
              </li>
            ))}
          </ul>
          <p className="line"></p>
          <h1 className="pdf-sub-heading">
            APOLLO HOSPITALS THANK YOU FOR YOUR CONSENT
          </h1>
          <p className="pdf-page-text">
            Based on current parameters, personal &#38; family history, AI
            enabled tools derive health risk scores to alert participant on
            his/her likely predisposition to a disease to aid early detection
            &#38; early medical intervention to mitigate the risk.
          </p>
          <div className="details-container">
            <h2 className="details-header">VITAL SIGNS</h2>
            <ul className="details-grid">
              {Object.entries(patientInfo["Vital Signs"]).map(
                ([key, value]) => (
                  <li key={key} className="details-item">
                    <p className="details-key">{key}</p>
                    <p className="details-value">{value}</p>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="details-container heart-health-container">
            <h2 className="details-header heart-health-header">
              LIFE STYLE ATTRIBUTES
            </h2>
            <ul className="details-grid">
              {Object.entries(patientInfo["Life Style"]).map(([key, value]) => (
                <li key={key} className="details-item">
                  <p className="details-key">{key}</p>
                  <p className="details-value heart-health-value">{value}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="details-container medical-container">
            <h2 className="details-header medical-header">MEDICAL HISTORY</h2>
            <ul className="details-grid">
              {Object.entries(patientInfo["Medical history"]).map(
                ([key, value]) => (
                  <li key={key} className="details-item">
                    <p className="details-key">{key}</p>
                    <p className="details-value medical-value">{value}</p>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="details-container heart-health-container">
            <h2 className="details-header heart-health-header">
              MEDICAL HISTORY
            </h2>
            <ul className="details-grid">
              {Object.entries(patientInfo["Lab History"]).map(
                ([key, value]) => (
                  <li key={key} className="details-item">
                    <p className="details-key">{key}</p>
                    <p className="details-value heart-health-value">{value}</p>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        <footer className="footer-container">
          <div className="d-flex flex-row justify-space-between plr-25">
            <p className="footer-text">Apollo Health</p>
            <div className="d-flex flex-column alingn-item-end">
              <p className="footer-text mb-4">
                This report is accessed by{" "}
                <span className="text-capital">{loginDetails.executive}</span>
              </p>
              <p className="footer-text">{renderCurrentDate()}</p>
            </div>
          </div>
          <div className="style-line-container">
            <div className="line-1"></div>
            <div className="line-2"></div>
          </div>
        </footer>
      </div>
      <div className="page">
        <div className="page-header">
          <h1 className="header-heading">Participant Report</h1>
          <img
            className="header-log"
            src="./apollo-white-logo.png"
            alt="apollo-logo"
          />
        </div>
        <div className="pdf-content-container">
          <div className="pdf-new-report-card pdf-report-margin-top">
            <h2 className="pdf-report-card-title">
              AI Calculated Risk Score Based on Your Inputs
            </h2>
            {apidata && (
              <div className="pdf-risk-section">
                <div className="pdf-risk-cards">
                  <div className="pdf-risk-card">
                    <h3 className="pdf-risk-card-title">Risk Status</h3>
                    <div className={`${riskCardColor} pdf-risk-highlight`}>
                      {apidata.risk}
                    </div>
                  </div>
                  <div className="pdf-risk-card">
                    <h3 className="pdf-risk-card-title">Patient Score</h3>
                    <p className={`pdf-risk-value`}>
                      {apidata.risk_score.toFixed(2)}%
                    </p>
                  </div>
                  <div className="pdf-risk-card">
                    <h3 className="pdf-risk-card-title">AI Score</h3>
                    <p className={`pdf-risk-value`}>
                      {(apidata.predicted_score * 10).toFixed(2)}
                    </p>
                  </div>
                </div>
                <p className="note-items">
                  Note:{" "}
                  <span className="">
                    The risk category is computed with the Machine Learing
                    Algorithm and categorised to provide an individual's Risk of
                    Liver Fibrosis. The Risk score is basaed on the probability
                    of No or Fibrosis (F0/f1) versus High Grades of Fibrosis
                    (F2/F4).
                  </span>
                </p>
              </div>
            )}
          </div>
          <div className="pdf-new-report-card">
            <h2 className="report-card-title">AI Recommended Protocol</h2>
            {apidata && <ProtocolTable data={apidata} />}
          </div>
          <div className="pdf-new-report-card">
            <h2 className="report-card-title">Disclaimer</h2>
            <Disclaimer />
          </div>
        </div>
        <footer className="footer-container">
          <div className="d-flex flex-row justify-space-between plr-25">
            <p className="footer-text">Apollo Health</p>
            <div className="d-flex flex-column alingn-item-end">
              <p className="footer-text mb-4">
                This report is accessed by{" "}
                <span className="text-capital">{loginDetails.executive}</span>
              </p>
              <p className="footer-text">{renderCurrentDate()}</p>
            </div>
          </div>
          <div className="style-line-container">
            <div className="line-1"></div>
            <div className="line-2"></div>
          </div>
        </footer>
      </div>
    </div>
  );
});

const LiverPdfDetails = (props) => {
  const componentRef = useRef();
  const navigate = useNavigate();

  const gotohome = () => {
    navigate("/");
  };

  return (
    <div className="pdf-report-button">
      <PrintPdfDetails ref={componentRef} PdfInfo={props.PdfInfo} />
      <div className="report-buttons-container">
        <button className="download-btn home-button" onClick={gotohome}>
          <img className="home-icon" src="./home-icon.svg" alt="download.svg" />
        </button>
        <ReactToPrint
          trigger={() => (
            <button className="download-btn download-button">
              <img
                className="download-icon"
                src="./cloud-download-outline.svg"
                alt="download.svg"
              />
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};

export default LiverPdfDetails;
