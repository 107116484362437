import "./index.css";

export default function Disclaimer() {
  const disclaimerPoints = [
    "This is not a diagnostic tool and it does not guarantee the accuracy of the result and cannot be independently acted upon.",
    "This Risk score and Clinical Algorithm is a general guideline for Physicians. Any additional laboratory investigations, Diagnostic Imaging, Treatment, or Patient Education related to lifestyle management is under the Physician’s or Cardiologist’s discretion.",
    "To ensure the information in the report is up to date, accurate, and correct, the Doctor shall be consulted to interpret the report.",
    "Apollo Hospitals and its Staff do not offer any assurance on the information made available or be liable for any loss or damage as the said report is based on the AICVD Cardiac Risk Score without any intervention from their side.",
    "By usage of the AICVD Cardiac Risk Score, it is deemed that the beneficiary of this service has agreed to get the same done at his own risk and further agrees with this disclaimer without any limitation or any clauses or sub-clauses.",
  ];

  return (
    <ul className="disclaimer-list">
      {disclaimerPoints.map((point, index) => (
        <li key={index} className="disclaimer-item">
          {point}
        </li>
      ))}
    </ul>
  );
}
