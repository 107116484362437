import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AICVDRoutes from "./components/projects/aicvd";
import LiverRoutes from "./components/projects/liver";
import PrediabetesRoutes from "./components/projects/prediabetes";
import CopdRoutes from "./components/projects/copd";

import { PatientProvider } from "./context/PatientContext";
import ProtectedRoute from "./components/ProtectRouter";
import Cookies from "js-cookie";
import LandingPage from "./components/LandingPage";
import Authentication from "./components/Login";
import {
  getImagesFromDB,
  fetchAndStoreImages,
  fetchAndStoreImagesAICVD,
} from "./utils/imageUtils";
import PrivacyPolicy from "./components/PrivacyPolicy";

const App = () => {
  const [token, setToken] = useState(Cookies.get("jwt_token"));
  const [sas_token, setsasToken] = useState(Cookies.get("sas_token"));

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateToken = (token, sas_token) => {
    setToken(token);
    setsasToken(sas_token);
  };

  useEffect(() => {
    const loadImages = async () => {
      const storedImages = await getImagesFromDB("home");
      if (storedImages.length > 0) {
        setImages(storedImages);
      } else {
        const urls = [
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/aicvd-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/prediabetes-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/copd-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/liver-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/ckd-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/ears-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/ews-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/anesthesia-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/anomoly-home-page.jpg",
          "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/tb-home-page.jpg",
        ];

        await fetchAndStoreImages("home", urls, setImages, sas_token);
      }
      setLoading(false);
    };

    loadImages();
  }, [sas_token]);

  useEffect(() => {
    const loadImages = async () => {
      const urls = [
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo-logo.png",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/iStock-644984072_vhiiap_c_scale%252Cw_1400.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/aicvd-app-page-1.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/aicvd-app-page-2.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/intepretation-img-1.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo_certificate_MD%20763515.png",
      ];
      await fetchAndStoreImagesAICVD("aicvd", urls, sas_token);
    };
    loadImages();
  }, [sas_token]);
  useEffect(() => {
    const loadImages = async () => {
      const urls = [
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo-logo.png",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/iStock-1296077052-min.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/prediabetes-app-page-1.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/prediabetes-app-page-2.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/intepretation-img-3.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo_certificate_MD%20763515.png",
      ];
      await fetchAndStoreImagesAICVD("prediabetes", urls, sas_token);
    };
    loadImages();
  }, [sas_token]);
  useEffect(() => {
    const loadImages = async () => {
      const urls = [
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo-logo.png",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/liver-landing-image-edited.jpeg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/liver-app-page-1.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/liver-app-page-2.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/intepretation-img-2.jpg",
        "https://clinicalpubres.blob.core.windows.net/pubres/clinical-img/apollo_certificate_MD%20763515.png",
      ];
      await fetchAndStoreImagesAICVD("liver", urls, sas_token);
    };
    loadImages();
  }, [sas_token]);

  return (
    <PatientProvider>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={<Authentication updateToken={updateToken} />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route
            path="/"
            element={
              token && sas_token ? (
                <LandingPage images={images} loading={loading} />
              ) : (
                <Authentication updateToken={updateToken} />
              )
            }
          />
          <Route
            path="/aicvd/*"
            element={<ProtectedRoute app="aicvd" element={AICVDRoutes} />}
          />
          <Route
            path="/prediabetes/*"
            element={
              <ProtectedRoute app="prediabetes" element={PrediabetesRoutes} />
            }
          />
          <Route
            path="/liver/*"
            element={<ProtectedRoute app="liver" element={LiverRoutes} />}
          />
          <Route
            path="/aicopd/*"
            element={<ProtectedRoute app="aicopd" element={CopdRoutes} />}
          />
        </Routes>
      </Router>
    </PatientProvider>
  );
};

export default App;
