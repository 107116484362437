import "./index.css";

export default function Disclaimer() {
  const disclaimerPoints = [
    "This is not a diagnostic tool, and it does not guarantee the accuracy of the result and cannot be independently acted upon.",
    "This risk score and clinical algorithm is a general guideline for physicians. Any additional laboratory investigations, diagnostic imaging, treatment, or patient education related to lifestyle management is under the physician’s or gastroenterologist's discretion.",
    "To ensure the information in the report is up-to-date, accurate, and correct, the doctor shall be consulted for interpretation of the report. He/she shall also ensure that the entries made in the API are correct.",
    "Apollo Hospitals and its Staff does not offer any assurance on the information made available or shall be liable for any loss or damage as the said report is based on the AI Liver Fibrosis Risk Score without any intervention from their side.",
    "By usage of AI-based Liver Fibrosis Risk Score, it is deemed that the beneficiary of this service has agreed to get the same done at his/her own risk and further agrees with this disclaimer without any limitation or any clauses or sub-clauses."  ];

  return (
    <ul className="disclaimer-list">
      {disclaimerPoints.map((point, index) => (
        <li key={index} className="disclaimer-item">
          {point}
        </li>
      ))}
    </ul>
  );
}
