import React, { Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import "./index.css";

// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class Shap extends Component {
  render() {
    const { shapValues, keyProp } = this.props;
    const options = {
      animationEnabled: true,
      theme: "light2",
      height: 410,
      axisX: {
        reversed: true,
        labelFontSize: window.innerWidth <= 576 ? 10 : 12,
        interval: 1,
        labelWrap: true,
        labelMaxWidth: window.innerWidth <= 576 ? 80 : 120,
        labelAngle: window.innerWidth <= 576 ? -45 : 0,
      },
      axisY: {
        gridThickness: 0,
        labelWrap: true,
        labelFontSize: window.innerWidth <= 576 ? 10 : 12,
        lineThickness: 1,
      },
      toolTip: {
        fontSize: window.innerWidth <= 576 ? 10 : 12,
      },
      responsive: true,
      maintainAspectRatio: false,
      data: [
        {
          type: "bar",
          dataPoints: Object.entries(shapValues).map((each) => ({
            y: parseFloat(each[1]),
            label: each[0],
            color: each[1] <= 0 ? "#FF024F" : "#008AFB",
          })),
        },
      ],
    };
    return (
      <div className="shap-main-plot" key={keyProp}>
        <CanvasJSChart options={options} />
      </div>
    );
  }
}
export default Shap;
