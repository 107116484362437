import "./index.css";

export default function Disclaimer() {
  const disclaimerPoints = [
    "This is not a diagnostic tool, and it does not guarantee the accuracy of the result and cannot be independently acted upon.",
    "This risk score and clinical algorithm is a general guideline for physicians. Any additional laboratory investigations, diagnostic imaging, treatment, or patient education related to lifestyle management is at the physician’s or endocrinologist’s discretion.",
    "To ensure the information in the report is up-to-date, accurate, and correct, the doctor shall be consulted for interpretation of the report.",
    "Apollo Hospitals and its staff do not offer any assurance on the information made available or be liable for any loss or damage as the said report is based on the Prediabetes Risk Score without any intervention from their side.",
    "By using the Prediabetes Risk Score, it is deemed that the beneficiary of this service has agreed to get the same done at his own risk and further agrees with this disclaimer without any limitation or any clauses or sub-clauses.",
    "Note on Uncontrolled / Gestational Diabetes and PCOD The current model doesn’t account for (exclusion criteria).",
    "Uncontrolled and Undiagnosed Diabetes",
    "Gestational Diabetes",
    "Polycystic Ovarian Disease (PCOD)",
    "Previously diagnosed and under treatment for Diabetes mellitus"
  ];

  return (
    <ul className="disclaimer-list">
      {disclaimerPoints.map((point, index) => (
        <li key={index} className="disclaimer-item">
          {point}
        </li>
      ))}
    </ul>
  );
}
