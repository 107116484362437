import React, { useEffect, useState } from "react";
import "./index.css";
import ProtocolTable from "./ProtocolTable";
import Disclaimer from "./Disclaimer";

const AICVDPatientReport = (props) => {
  const [apidata, setAPI] = useState();

  useEffect(() => {
    const report = props.data.Data[0];
    const prediction = report.Prediction;
    let topRiskContributors = prediction.HeartRisk.TopRiskContributors;

    setAPI({
      optimal: prediction.HeartRisk.Acceptable,
      risk: prediction.HeartRisk.Risk,
      score: prediction.HeartRisk.Score,
      Ratio: prediction.HeartRisk.Ratio,
      topRiskContributors: topRiskContributors,
      department: prediction.MedicalProtocol.Referral.Department,
      urgency: prediction.MedicalProtocol.Referral.Urgency,
      advice: prediction.MedicalProtocol.Management.GeneralAdvice,
      medication: prediction.MedicalProtocol.Medication,
      diagnosticsAndImagingRecommended:
        prediction.MedicalProtocol.DiagnosticsAndImagingRecommended,
      labInvestigationRecommended:
        prediction.MedicalProtocol.LabInvestigationRecommended,
      repeatVisitComment: prediction.ManagementRepeatVisit,
      repeatVisitDuration:
        prediction.MedicalProtocol.Management.RepeatVisit.Duration,
      repeatVisitUnits: prediction.MedicalProtocol.Management.RepeatVisit.Unit,
      loaderStatus: false,
      aicvd_note: report.aicvd_note,
      treatment_goals: report.treatment_goals,
      AICVD_Cardiac_Risk_Score: report.AICVD_Cardiac_Risk_Score,
      note: report.note,
      riskProfile: report.risk_profile,
    });
  }, [props]);

  let riskCardColor = "";

  if (apidata && apidata.risk === "High Risk")
    riskCardColor = "risk-high-color";
  else if (apidata && apidata.risk === "Moderate Risk")
    riskCardColor = "risk-medium-color";
  else riskCardColor = "risk-low-color";

  return (
    <div>
      {apidata && (
        <div className="new-report-container">
          <div className="new-report-card">
            <h2 className="report-card-title">
              AI Calculated Risk Score Based on Your Inputs
            </h2>
            <div className="risk-section">
              <div className="risk-cards">
                <div className="risk-card">
                  <h3 className="risk-card-title">Risk Status</h3>
                  <div className={`${riskCardColor} risk-highlight`}>
                    {apidata.risk}
                  </div>
                </div>
                <div className="risk-card">
                  <h3 className="risk-card-title">Patient Score</h3>
                  <p className={`risk-value`}>{apidata.score}%</p>
                </div>
                <div className="risk-card">
                  <h3 className="risk-card-title">Acceptable Score</h3>
                  <p className={`risk-value`}>{apidata.optimal}</p>
                </div>
                <div className="risk-card">
                  <h3 className="risk-card-title">Risk Profile</h3>
                  <p className={`risk-value`}>{apidata.Ratio.toFixed(2)}x</p>
                </div>
              </div>
              <p className="note-items">{apidata.note}</p>
              <p className="note-items">
                Note: <span className="">{apidata.aicvd_note}</span>
              </p>
            </div>
          </div>
          <div className="new-report-card">
            <h2 className="report-card-title">Top Modifible Risk Factors</h2>
            <div className="risk-section">
              <div className="risk-cards">
                {apidata.topRiskContributors !== null
                  ? apidata.topRiskContributors?.map((each, index) => (
                      <div className="risk-card">
                        <p className={`risk-value`}>
                          {each === "bmi" ? "BMI" : each}
                        </p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
          <div className="new-report-card">
            <h2 className="report-card-title">AI Recommended Protocol</h2>
            <ProtocolTable data={apidata} />
          </div>
          <div className="new-report-card">
            <h2 className="report-card-title">Disclaimer</h2>
            <Disclaimer />
          </div>
        </div>
      )}
    </div>
  );
};

export default AICVDPatientReport;
