import React from "react";
import "./index.css";

const Section = ({ section, renderedInUL }) => {
  const renderContent = (section) => {
    switch (section.type) {
      case "heading":
        if (
          section.level === 1 &&
          section.text_content.trim().toUpperCase() ===
            "STUDY PARTICIPANT INFORMATION SHEET"
        ) {
          return null; // Hide this specific heading
        }
        return React.createElement(
          `h${Math.min(section.level, 6)}`,
          { className: `heading heading-level-${section.level}` },
          section.text_content
        );
      case "para":
        return <p className="consent-paragraph">{section.text_content}</p>;
      case "note":
        return (
          <blockquote className="consent-note">
            {section.text_content}
          </blockquote>
        );
      case "list":
        return (
          <ul className="consent-list">
            {section.sub_section.map((subSec, idx) => (
              <Section key={idx} renderedInUL={true} section={subSec} />
            ))}
          </ul>
        );
      case "list_item":
        return renderedInUL ? (
          <li className="consent-list-item">{section.text_content}</li>
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div className="section-container">
      {renderContent(section)}
      {section.sub_section &&
        section.sub_section.map((subSec, idx) => (
          <Section key={idx} section={subSec} />
        ))}
    </div>
  );
};

// Main Consent Component
const PatientConsent = ({ data }) => {
  return (
    <div id="consentForm">
      <Section section={data} />
    </div>
  );
};

export default PatientConsent;
